//================================================================
//  Consumption Page
//
//    12/10/2022 ~ Benno didn't clean up this page during the portal 2.0 work
//                 This page will be replaced in the next few sprints!
//
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react'

//Contexts
import {GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import BarChart from '../../Components/Charts/BarChart';
import ExportToCSV from '../../Components/ExportToCSV/ExportToCSV';

//Functions
import GetDocument from '../../Library/GetDocument';
import ReactBackend from '../../Library/reactBackend';

//Images
import InfoIcon from '../../Components/Images/Icon_Info_Black.svg';
import LoadingIcon from '../../Components/Images/Loading_Ripple.svg';
import OpenIcon from '../../Components/Images/right_arrow_icon.svg';
import upArrow from '../../Components/Images/up_arrow.svg';
import downArrow from '../../Components/Images/down_arrow.svg';

//CSS
import './Consumption.css';


export default function Consumption() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'onload', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    // Holds all projects
    const [allProjects, setAllProjects] = useState([]);

    //Holds the API response
    const [consumption, setConsumption] = useState();

    //Selected FY by the user
    const [selectedFY, setSelectedFY] = useState();

    //Used to work around resizing bug - https://github.com/rakannimer/react-google-charts/issues/209#issuecomment-584596670
    const [chartWidthKey, setChartWidthKey] = useState(false);
    const windowWidth = window.innerWidth;

  //------------------------------------------------------
  //  API CAlls
  //------------------------------------------------------

    //Used to call "getConsumptionAPI"
    async function getConsumptionAPI(projects){

      const requestBody = {
        "projectIds": projects
      };

      //Call React Backend - Submit Form Response
      ReactBackend('getConsumption', requestBody).then((response) => {

        if(response.status === 'success'){

          setConsumption(response.responseBody.message);
          setPageStatus('success');

        // Should have an error message
        } else if(response.status === 'error-invalid'){ 

          setConsumption(undefined);
          setAppErrors(response.responseBody.message);
          setPageStatus('error-invalid');

        // Catch all
        } else {

          setConsumption(undefined);
          setAppErrors(response.responseBody.message);
          setPageStatus('error-fatal');

        }

      });

    }   

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Formats the API data into something the barchart can understand
    //Due to how barcharts works, we need to do some stupid stuff .... :D :) :| :\ :( 
    function HandleBarChartInput(response, targetMonth){

      //Get the correct month value
      var results = 0;
      response?.monthlySummary?.forEach(object => {

        var monthYear = object.month;
        if (monthYear.slice(4,6) === targetMonth){

          results = object.total;
  
        }
    
      });  
      
      return results;

    }

    //Handles undefined values been passed into a map/table
    function HandleTableInput(array){

      if (array !== undefined && Array.isArray(array)){

        return array;

      } else {

        return [];

      }
    }

    //Converts invoice to 'month' of the year
    function GetMonth(value){
    
      if (typeof(value) === "string" && value.length === 6){

        //Get the current month/year > convert to 'date' object
        var year = value.slice(0,4);
        var month = value.slice(4,6);
        var date = new Date(`${year}-${month}-01`);

        //Extract the target month
        date = date.toLocaleString('en-US', {month: 'short'});
        return date;

      } else {

        return null;
      
      }
    }

    //Returns a formated currency conversion
    function CurrencyConversion(cost){

      if (typeof(cost) === 'number'){
      
        return new Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD', currencyDisplay: "symbol"}).format(cost);
      
      } else {

        return '-';

      }

    }

    //Returns the 'trends' component
    function TrendsLabels(value){

      if (value > 0){

        return (
          <div className="Consumption-Trends-Container" style={{color: "red"}}>
            <img style={{width: "20px", height: "20px", marginRight: "10px"}} alt="up-arrow" src={upArrow}></img>
            {value}%
          </div>
        );
      }
      else if (value < 0){

        return (
          <div className="Consumption-Trends-Container" style={{color: "green"}}>
            <img style={{width: "20px", height: "20px", marginRight: "10px"}} alt="up-arrow" src={downArrow}></img>
            {value}%
          </div>
        );

      } else {

        return (
          <div className="Consumption-Trends-Container">
            -
          </div>
        );

      }
    }

    // Changes the page view
    // Benno ~ This is a hack, but this page will be replaced
    function ChangeView(rowData){

      if (rowData === undefined) {

        setSelectedFY(undefined);
        setPageStatus('success');

      } else {

        setSelectedFY(rowData);
        setPageStatus('error-other');
  
      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload > Get the consumption data for all projects
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Reset useStates and show pending screen
      setPageStatus('onload');
      setAllProjects([]);

      // Project view was selected
      if (getUser.preferences.globalSelector.selectedView === 'project') {

        setAllProjects([
          getUser.preferences.globalSelector.selectedResource
        ]);

        // Call the API and handle any errors
        getConsumptionAPI([getUser.preferences.globalSelector.selectedResource]);
      
      }

      // Portfolio view was selected
      if (getUser.preferences.globalSelector.selectedView === 'portfolio') {

        GetDocument('portfolios', getUser.preferences.globalSelector.selectedResource)
        .then((portfolio) => {

          // Extract the projects from the portfolio document
          const projects = portfolio?.projects;
          setAllProjects([...projects]);

          // Call the API and handle any errors
          getConsumptionAPI(projects);

        })
        .catch((error) =>{
          setAppErrors(`Unable to retrieve projects from the portfolio ${getUser.preferences.id} ${error}`);
          setPageStatus('error-fatal');

        });
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser]);

    // Update width of chart if window size changes
    useEffect(() => {

      setChartWidthKey(prevWidth => !prevWidth);

    }, [windowWidth]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedViews={['project', 'portfolio']}
        requireSelectedResource={true}
        requiredRoles={['projectOwner', 'projectOperator', 'portfolioOwner', 'portfolioViewer']}
        status={pageStatus}
        header={
          <PageHeader
            title={'Consumption'}
            body={
              <>
                View a summary of costs incurred for your projects here.
              </>
            }
          ></PageHeader>
        }
        // Loading screen, as retrieving consumption can take some time!
        body={
          <>
            <div className='PageComponent-Label'>
              <div className='PageComponent-LabelMessage'>
                <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                <p>Searching {allProjects?.length > 1 ? `${allProjects?.length} projects` : `${allProjects?.length} project`} this will take a moment...</p>
              </div>
            </div>

            <div style={{textAlign: 'center'}}>
              <img alt="loading-circle-icon" src={LoadingIcon}></img>
            </div>
          </>
        }
        successContent={
          <div className="Consumption-Container">

            {/* Onload Table */}
            <table className="Consumption-Table">
              <colgroup>
                <col span="1" style={{width: "180px"}}></col>
              </colgroup>
              <tbody>
                <tr>
                  <th></th>
                  <th>Financial Year</th>
                  <th>Spend (USD)</th>
                </tr>
                {
                  HandleTableInput(consumption).map(row =>(
                    <tr className="Consumption-Table-FY" onClick={() => ChangeView(row)}>
                      <td style={{display: "flex", alignItems: "center"}}>
                        View report
                        <img style={{width: "20px", height: "20px", marginLeft: "10px"}} alt="hero-icon" src={OpenIcon}></img>
                      </td>
                      <td>{row.financialYear}</td>
                      <td>{CurrencyConversion(row.totalSpend)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>

          </div>
        }
        errorOtherContent={
          <div className='Consumption-Dashboard'>

            {/* Breadcrumbs and Header */}
            <div>
              <div className="Breadcrumbs-Container">
                <p className="Breadcrumbs-Link" onClick={() => ChangeView(undefined)}> Return to Consumption</p>
              </div>

              <PageHeader
                title={'Consumption'}
                body={'View a summary of costs incurred for your projects here.'}
              ></PageHeader>
            </div>

            {/* Portfolio Summary */}
            <div className="Form-Body">
              <div className="Portfolio-View-Container-Success-1">

                {/* Subtitle-1 */}
                <div className="Consumption-View-Subtitle-1">
                  <h2>Portfolio Summary</h2>
                  <p>The figures displayed here reflect the total costs incurred to date for your Portfolio (includes all invoiced items).</p>
                </div>

                {/* Table 1 */}
                <div className="Consumption-View-Table-1">
                  <table className="Consumption-Table">
                    <tbody>
                      <tr>
                        <th>{GetMonth(selectedFY?.previousMonth)} (USD)</th>
                        <th>{GetMonth(selectedFY?.latestMonth)} (USD)</th>
                        <th>Total in {selectedFY?.financialYear} (USD)</th>
                        <th>
                          Trend (
                            {GetMonth(selectedFY?.previousMonth) 
                            + " - " + 
                            GetMonth(selectedFY?.latestMonth)}
                          )
                          </th>
                      </tr>
                      <tr>
                        <td>{CurrencyConversion(selectedFY?.previousTotal)}</td>
                        <td>{CurrencyConversion(selectedFY?.latestTotal)}</td>
                        <td>{CurrencyConversion(selectedFY?.totalSpend)}</td>
                        <td className="Consumption-Trends-Container">
                          {TrendsLabels(selectedFY?.trend)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
                              
            {/* Portfolio Cost Per Month */}
            <div className='Form-Body'>
              
              {/* Subtitle for Bar Chart */}
              <div className="Consumption-View-Subtitle-2">
                <h2>Portfolio Cost Per Month</h2>
              </div>

              {/* Bar Chart */}
              <div className="Consumption-View-Chart">
                <BarChart
                  width={'100%'}
                  key={chartWidthKey}
                  height={'200px'}
                  data={[
                    ["", "USD $"],
                    ["Jul", HandleBarChartInput(selectedFY, "07")],
                    ["Aug", HandleBarChartInput(selectedFY, "08")],
                    ["Sep", HandleBarChartInput(selectedFY, "09")],
                    ["Oct", HandleBarChartInput(selectedFY, "10")],
                    ["Nov", HandleBarChartInput(selectedFY, "11")],
                    ["Dec", HandleBarChartInput(selectedFY, "12")],
                    ["Jan", HandleBarChartInput(selectedFY, "01")],
                    ["Feb", HandleBarChartInput(selectedFY, "02")],
                    ["Mar", HandleBarChartInput(selectedFY, "03")],
                    ["Apr", HandleBarChartInput(selectedFY, "04")],
                    ["May", HandleBarChartInput(selectedFY, "05")],
                    ["Jun", HandleBarChartInput(selectedFY, "06")]
                  ]}
                  options={{
                    legend: { position: 'none' },
                  }}
                >
                </BarChart>
              </div>
              
            </div>

            {/* Project Summary */}
            <div className="Form-Body">
              <div className="Consumption-View-Container-Success-2">

                {/* Subtitle for Projects table */}
                <div className="Consumption-View-Subtitle-3">
                  <h2>Project Summary</h2>

                  <ExportToCSV
                    filename='consumption'
                    data={selectedFY?.projects} 
                  ></ExportToCSV>   
                
                </div>

                {/* Projects Table */}
                <div className="Consumption-View-Table-2">
                  <table className="Consumption-Table">
                    <colgroup>
                      <col span="1" style={{width: "25%"}}></col>
                      <col span="1" style={{width: "25%"}}></col>
                      <col span="1" style={{width: "15%"}}></col>
                      <col span="1" style={{width: "15%"}}></col>
                      <col span="1" style={{width: "15%"}}></col>
                      <col span="1" style={{width: "15%"}}></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>Project</th>
                        <th>Owner</th>
                        <th>{GetMonth(selectedFY?.previousMonth)} (USD)</th>
                        <th>{GetMonth(selectedFY?.latestMonth)} (USD)</th>
                        <th>Total in {selectedFY?.financialYear} (USD)</th>
                        <th>
                          Trend (
                            {GetMonth(selectedFY?.previousMonth) 
                            + " - " + 
                            GetMonth(selectedFY?.latestMonth)}
                          )
                          </th>
                      </tr>
                      {
                        selectedFY?.projects?.map(row =>(
                          <tr>
                            <td>{row.projectId}</td>
                            <td>{row.owner}</td>
                            <td>{CurrencyConversion(row.previousTotal)}</td>
                            <td>{CurrencyConversion(row.latestTotal)}</td>
                            <td>{CurrencyConversion(row.totalSpend)}</td>
                            <td>{TrendsLabels(row.trend)}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>


          </div>
        }
      ></PageComponent>
    )
}