//================================================================
//  Home page
//================================================================

//Libraries
import React, { useState, useContext, useEffect } from 'react';
import {useNavigate, Link} from "react-router-dom";

//Contexts
import {GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import DashboardTile from './Components/DashboardTile';

//Functions
import GetDocument from '../../Library/GetDocument';
import QueryDocument from '../../Library/QueryDocument';
import ReactBackend from '../../Library/reactBackend';

//Images
import ProjectInfoIcon from '../../Components/Images/Icon_ProjectInfo_Blue.svg';
import NetworkingIcon from '../../Components/Images/Icon_Networking_Blue.svg';
import BillingIcon from '../../Components/Images/Icon_Billing_Blue.svg';
import GroupsIcon from '../../Components/Images/Icon_Groups_Blue.svg';
import SecurityIcon from '../../Components/Images/Icon_Security_Blue.svg';
import SustainabilityIcon from '../../Components/Images/Icon_Sustainability_Blue.svg';
import PortfolioIcon from '../../Components/Images/Icon_Portfolio_Blue.svg';
import DownwardTrend from '../../Components/Images/DownwardTrend.svg';
import UpwardTrend from '../../Components/Images/UpwardTrend.svg';
import CloudIcon from '../../Components/Images/Icon_Cloud_Blue.svg';
import BookIcon from '../../Components/Images/Icon_Book_Blue.svg';
import ContactUs from '../../Components/Images/Icon_Contact_Us.svg'

//CSS
import './Home.css';


export default function Home() {

  //------------------------------------------------------
  //  React Router 
  //------------------------------------------------------

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to determine the status of the page
    const [pageStatus, setPageStatus] = useState("pending");

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Used to store the dashboard metrics
    const [dashboardMetrics, setDashboardMetrics] = useState();

    //Calc the current month & year
    const [currentMonthYear] = useState(`${new Date().toLocaleString('en-US', {month: 'short'})} ${new Date().getFullYear()}`);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Returns the correct styling for upward vs. downward trend in carbon emissions
    function getTrendStyle(trend){

      //Check if negative to determine upward/downward trend
      if(trend?.length > 1 && trend?.charAt(0) === "-"){
        return (
          <span className='Monthly-Emissions-Trend-Down'>
            <img src={DownwardTrend} alt="trend-up" style={{marginRight: "5px"}}></img>
            {trend}
          </span>
        )
      } else if(trend !== "-" && trend !== "") {
        return(
          <span className='Monthly-Emissions-Trend-Up'>
            <img src={UpwardTrend} alt="trend-up" style={{marginRight: "5px"}}></img>
            {trend}
          </span>
        )
      } else if(trend === "") {
        return(
          <span>
            -
          </span>
        )
      } else {
        return(
          <span>
            {trend}
          </span>
        )
      }
    }

    
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      //Temporary dashboardMetricsObject
      var tempDashboardMetrics = {};

      //------------------------------------------------------
      //  View is 'welcome'
      //------------------------------------------------------
      

      if (getUser?.preferences?.globalSelector?.selectedResource === 'create or select a project'){

        //Welcome screen
        setPageStatus('success');
        
      } else {

        //Home page (default)
        setPageStatus('onload');

      }

      //------------------------------------------------------
      //  View is 'project'
      //------------------------------------------------------
      
      if (getUser?.preferences?.globalSelector?.selectedView === 'project') {

        const projectInformationPromise = GetDocument('projects', getUser?.preferences?.globalSelector?.selectedResource)
        const securityFindingsPromise = GetDocument('security-sccfindings', getUser?.preferences?.globalSelector?.selectedResource)
        const monthPromise = QueryDocument("month", "", ["month", "desc"], 1)
        const sustainabilityTotalPromise = QueryDocument(`sustainability/${getUser?.preferences?.globalSelector?.selectedResource}/months`, "", ["month_int", "desc"], 1)
        const billingPromise = ReactBackend('getLatestInvoice', {'projectId': getUser?.preferences?.globalSelector?.selectedResource});

      
        //Resolve all the promises > Set page status to 'success'
        Promise.allSettled([projectInformationPromise, securityFindingsPromise, monthPromise, sustainabilityTotalPromise, billingPromise])
        .then((results) => {

          const projectInformationResults = results[0];
          const securityFindingsResults = results[1];
          const monthResults = results[2];
          const sustainabilityTotalResults = results[3];
          const billingResults = results[4];

          //------------------------------------------------------
          //  Resolve Project Information Promise
          //------------------------------------------------------

          if(projectInformationResults.status === 'fulfilled') {

            tempDashboardMetrics.projectinformation = {
              "projectowner": projectInformationResults.value.projectowner,
              "budget": projectInformationResults.value.budget
            }

          }

          //------------------------------------------------------
          //  Resolve Security Findings Promise
          //------------------------------------------------------

          if(securityFindingsResults.status === 'fulfilled') {

            // Convert nested object to array of objects
            const findings = []
            Object.values(securityFindingsResults.value)?.map((obj) =>(
              findings.push(obj)
            ))

            tempDashboardMetrics.projectsecurityfindings = {
              "critical": findings?.filter((value) => (value.severityLevel === 'CRITICAL')).length,
              "high": findings?.filter((value) => (value.severityLevel === 'HIGH')).length,
              "medium": findings?.filter((value) => (value.severityLevel === 'MEDIUM')).length,
              "low": findings?.filter((value) => (value.severityLevel === 'LOW')).length,
              "other": findings?.filter((value) => (value.severityLevel === 'UNCATEGORIZED')).length
            }

          }

          //------------------------------------------------------
          //  Resolve Sustainability Promise
          //------------------------------------------------------

          if(monthResults.status === 'fulfilled' && sustainabilityTotalResults.status === 'fulfilled') {

            tempDashboardMetrics.projectsustainabilitytotal = {
              "latestmonth": monthResults.value[0].displayName,
              "latesttotal": sustainabilityTotalResults.value[0]?.total_emissions,
              "trend": sustainabilityTotalResults.value[0]?.trend
            }

          }

          //------------------------------------------------------
          //  Resolve Billing Promise
          //------------------------------------------------------

          if(billingResults?.status === 'fulfilled'){

            tempDashboardMetrics.projectbillingtotal = {
              "latestmonth": billingResults.value?.responseBody?.message?.month,
              "latesttotal": billingResults.value?.responseBody?.message?.projectCurrencyTotal,
              "currency": billingResults.value?.responseBody?.message?.projectCurrencyCode
            }

          }

          //------------------------------------------------------
          //  Update dashboardMetrics
          //------------------------------------------------------

          setDashboardMetrics(tempDashboardMetrics);

        })
        .catch((error) => {

          console.log(`Failed to get dashboard metrics for '${getUser?.preferences?.globalSelector?.selectedResource}'. ${error}`);
          setAppErrors(`Failed to get dashboard metrics for '${getUser?.preferences?.globalSelector?.selectedResource}'. ${error}`);
          setPageStatus('error-invalid');

        })

      }

      //------------------------------------------------------
      //  View is 'portfolio'
      //------------------------------------------------------
      
      if (getUser?.preferences?.globalSelector?.selectedView === 'portfolio') {

        //------------------------------------------------------
        //  Get Portfolio (Promise)
        //------------------------------------------------------

        GetDocument('portfolios', getUser?.preferences?.globalSelector?.selectedResource)
        .then((results) => {

          //Save portfolio name, description, projects
          tempDashboardMetrics.portfolioinformation = {
            "name": results.portfolioname,
            "description": results.description,
            "projects": results.projects
          }

          if(results.projects?.length === 0) return;

          //Define all array of promises
          const arrayOfSCCPromises = [];
          const arrayOfSustainabilityPromises = [];

          //Loop through projects > get SCC findings, emissions and invoice totals
          results.projects?.forEach(project => {

            arrayOfSCCPromises.push(GetDocument('security-sccfindings', project));
            arrayOfSustainabilityPromises.push(QueryDocument(`sustainability/${project}/months`, "", ["month_int", "desc"], 1));
          
          });

          //------------------------------------------------------
          //  Extract Array of Promises (x2)
          //------------------------------------------------------

          Promise.allSettled([arrayOfSCCPromises, arrayOfSustainabilityPromises])
          .then((settledPromises) =>{

            const securityFindingsResults = settledPromises[0];
            const sustainabilityResults = settledPromises[1];

            //------------------------------------------------------
            //  Settle Sustainability Promise (Array)
            //------------------------------------------------------

            Promise.allSettled(sustainabilityResults.value)
            .then((settledSustainabilityPromises) =>{

              const monthlyEmissions = [];
              settledSustainabilityPromises?.forEach(promise =>{

                // An unknown error has occurred
                if (promise?.status === 'rejected' && !(promise?.reason?.message?.includes('does not exist'))) {

                  setAppErrors(`Failed to get monthly emissions total for project attached to '${getUser?.preferences?.globalSelector?.selectedResource}'. ${promise?.reason?.message}`);
                  setPageStatus('error-invalid');

                // No emissions for this project!
                } else if (promise?.status === 'rejected') return;

                //Push emissions total to monthly_emissions
                monthlyEmissions.push(promise?.value[0]);

              });

              //Get project with the highest carbon footprint
              tempDashboardMetrics.portfolioSustainabilityProject = monthlyEmissions.sort((a, b) => (a.total_emissions < b.total_emissions) ? 1 : -1)[0].projectid;

              //------------------------------------------------------
              //  Settle Security Findings Promise (Array)
              //------------------------------------------------------

              Promise.allSettled(securityFindingsResults.value)
              .then((settledSecurityPromises) =>{
               
                const findings = [];
                settledSecurityPromises?.forEach(promise =>{

                  // An unknown error has occurred
                  if (promise?.status === 'rejected' && !(promise?.reason?.message?.includes('does not exist'))) {

                    setAppErrors(`Failed to get security findings for project attached to '${getUser?.preferences?.globalSelector?.selectedResource}'. ${promise?.reason?.message}`);
                    setPageStatus('error-invalid');

                  // No SCC findings for this project!
                  } else if (promise?.status === 'rejected') return;

                  // Convert nested object to an array of objects
                  Object.values(promise?.value)?.map((obj) =>(
                    findings.push(obj)
                  ));

                });

                // Create a new array by 'severityLevel'(
                tempDashboardMetrics.portfoliosecurityfindings = {
                  "critical": findings?.filter((value) => (value.severityLevel === 'CRITICAL')).length,
                  "high": findings?.filter((value) => (value.severityLevel === 'HIGH')).length,
                  "medium": findings?.filter((value) => (value.severityLevel === 'MEDIUM')).length,
                  "low": findings?.filter((value) => (value.severityLevel === 'LOW')).length,
                  "other": findings?.filter((value) => (value.severityLevel === 'UNCATEGORIZED')).length
                }

              })

            //------------------------------------------------------
            //  Update dashboardMetrics
            //------------------------------------------------------

              setDashboardMetrics(tempDashboardMetrics);

            })

          })

        })
        .catch((error) => {

          console.log(`Failed to get dashboard metrics for '${getUser?.preferences?.globalSelector?.selectedResource}'. ${error}`);
          setAppErrors(`Failed to get dashboard metrics for '${getUser?.preferences?.globalSelector?.selectedResource}'. ${error}`);
          setPageStatus('error-invalid');

        });

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={true}
        //This is for PageComponent.js welcome page. Welcome, Projects, Portfolios
        requireSelectedViews={['welcome', 'project', 'portfolio']}
        status={pageStatus}
        body={
          <div className="Dashboard-Container">

            {
              // ---------------------------------------------
              //  Project Dashboard 
              // ---------------------------------------------

              getUser?.preferences?.globalSelector?.selectedView === 'project' ?
              (
                <>
                  {/* Column 1 */}
                  <div className="Dashboard-Col1">

                    {/* ---------------------------- */}
                    {/*  Project Information         */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={'Project Information'}
                      icon={<img alt='Project_Icon' src={ProjectInfoIcon}></img>}
                      body={
                        dashboardMetrics?.projectinformation ?
                        (
                          <div className='Dashboard-Tile-Project-Body'>
                            {/* Project Owner */}
                            <div>
                              <p className='Dashboard-Tile-Project-Fields'>Project Owner</p>
                              {dashboardMetrics?.projectinformation?.projectowner ? dashboardMetrics?.projectinformation?.projectowner : "-"}
                            </div>

                            {/* Budget */}
                            <div>
                              <p className='Dashboard-Tile-Project-Fields'>Budget</p>
                              ${dashboardMetrics?.projectinformation?.budget ? dashboardMetrics?.projectinformation?.budget : '-'}
                            </div>
                          </div>
                        )
                        :
                        "No data found."
                      }
                      footer={<a href={`https://console.cloud.google.com/home/dashboard?project=${getUser?.preferences?.globalSelector?.selectedResource}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "#4E4E4E"}}>Go to the GCP Console</a>}
                    ></DashboardTile> 

                    {/* ---------------------------- */}
                    {/*  Users & Groups              */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Users & Groups"}
                      icon={<img alt="Groups_Icon" src={GroupsIcon}></img>}
                      body={"Manage who has access to your project using groups"}
                      footer={<div onClick={() => navigate("/projects/projectgroups")}>Grant/Remove access to your Project</div>}
                    ></DashboardTile> 
                      
                  </div>
            
                  {/* Column 2 */}
                  <div className="Dashboard-Col2">

                    {/* ---------------------------- */}
                    {/*  Networking                  */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Networking"}
                      icon={<img alt="Project_Icon" src={NetworkingIcon}></img>}
                      body={"Connect to the Lendlease network and self-serve firewall rules"}
                      footer={<div onClick={() => navigate("/sharedvpc")}>Attach to the Shared VPC</div>}
                    ></DashboardTile>  

                    {/* ---------------------------- */}
                    {/*  Security                    */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Security"}
                      icon={<img alt="Security_Icon" src={SecurityIcon}></img>}
                      body={
                        <div className='Dashboard-Tile-Security-Tile'>
                          {
                            dashboardMetrics?.projectsecurityfindings ?
                            (
                              <>
                                {/* Critical Findings */}
                                <div className='Dashboard-Tile-Security-Finding-Summary'>
                                  <div>
                                    <span className="Dot" style={{backgroundColor:" #C6221E"}}></span>
                                    Critical Findings 
                                  </div>
                                  {dashboardMetrics?.projectsecurityfindings?.critical ? dashboardMetrics?.projectsecurityfindings?.critical : 0}
                                </div>

                                {/* High Findings */}
                                <div className='Dashboard-Tile-Security-Finding-Summary'>
                                  <div>
                                    <span className="Dot" style={{backgroundColor:" #EA4335"}}></span>
                                    High Findings 
                                  </div>
                                  {dashboardMetrics?.projectsecurityfindings?.high ? dashboardMetrics?.projectsecurityfindings?.high : 0}
                                </div>

                                {/* Medium Findings */}
                                <div className='Dashboard-Tile-Security-Finding-Summary'>
                                  <div>
                                    <span className="Dot" style={{backgroundColor:" #FA7B17"}}></span>
                                    Medium Findings 
                                  </div>
                                  {dashboardMetrics?.projectsecurityfindings?.medium ? dashboardMetrics?.projectsecurityfindings?.medium : 0}
                                </div>

                                {/* Low Findings */}
                                <div className='Dashboard-Tile-Security-Finding-Summary'>
                                  <div>
                                    <span className="Dot" style={{backgroundColor:" #F9AB00"}}></span>
                                    Low Findings 
                                  </div>
                                  {dashboardMetrics?.projectsecurityfindings?.low ? dashboardMetrics?.projectsecurityfindings?.low : 0}
                                </div>

                                {/* Other Findings */}
                                <div className='Dashboard-Tile-Security-Finding-Summary'>
                                  <div>
                                    <span className="Dot" style={{backgroundColor:" #BDC1C7"}}></span>
                                    Other Findings 
                                  </div>
                                  {dashboardMetrics?.projectsecurityfindings?.other ? dashboardMetrics?.projectsecurityfindings?.other : 0}
                                </div>

                              </>
                            )
                            :
                            (
                              <p>No data found.</p>
                            )
                          }
                        </div>
                      }
                      footer={<div onClick={() => navigate("/security")}>View security findings</div>}
                    ></DashboardTile>  

                  </div>
                  
                  {/* Column 3 */}
                  <div className="Dashboard-Col3">

                    {/* ---------------------------- */}
                    {/*  Billing                     */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Billing"}
                      icon={<img alt="Project_Icon" src={BillingIcon}></img>}
                      body={
                        <div className='Dashboard-Tile-Billing'>
                          Latest Invoice Total for {dashboardMetrics?.projectbillingtotal?.latestmonth ? dashboardMetrics?.projectbillingtotal?.latestmonth : `${currentMonthYear}`}
                          <p>
                            {
                              dashboardMetrics?.projectbillingtotal?.latesttotal ?
                              (
                               <>
                                  {dashboardMetrics?.projectbillingtotal?.latesttotal ? dashboardMetrics?.projectbillingtotal?.latesttotal : 0} {dashboardMetrics?.projectbillingtotal?.currency ? dashboardMetrics?.projectbillingtotal?.currency : ""}
                               </>
                              )
                              :
                              "No data found."
                            }
                          </p>
                        </div>
                      }
                      footer={
                        <div onClick={() => navigate("/billing")}>View latest invoice</div>
                      }
                    ></DashboardTile>  

                    {/* ---------------------------- */}
                    {/*  Sustainability              */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Sustainability"}
                      icon={<img alt="Project_Icon" src={SustainabilityIcon}></img>}
                      body={
                        <div className='Dashboard-Tile-Sustainability-Tile'>
                          Total Emissions for {dashboardMetrics?.projectsustainabilitytotal?.latestmonth ? dashboardMetrics?.projectsustainabilitytotal?.latestmonth : `${currentMonthYear}`}
                          {
                            dashboardMetrics?.projectsustainabilitytotal ?
                            (
                              <div>
                                {dashboardMetrics?.projectsustainabilitytotal?.latesttotal ? dashboardMetrics?.projectsustainabilitytotal?.latesttotal : 0} kgCO2e
                                {dashboardMetrics?.projectsustainabilitytotal?.trend ? getTrendStyle(dashboardMetrics?.projectsustainabilitytotal?.trend) : 0}
                              </div>
                            )
                            :
                            "No data found"
                          }
                        </div>
                      }
                      footer={
                        <div onClick={() => navigate("/sustainability")}>View monthly emissions</div>
                      }
                    ></DashboardTile>  
                    
                  </div>
                </>
              )

              // ---------------------------------------------
              //  Portfolio Dashboard 
              // ---------------------------------------------

              : getUser?.preferences?.globalSelector?.selectedView === 'portfolio' ?
              (
                <>

                  {/* Column 1 */}
                  <div className="Dashboard-Col1">

                    {/* ---------------------------- */}
                    {/*  Portfolio Information       */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Portfolio Information"}
                      icon={<img alt="Portfolio_Icon" src={PortfolioIcon}></img>}
                      body={
                        dashboardMetrics?.portfolioinformation ?
                        (
                          <div className='Dashboard-Tile-Portfolio-Information-Tile'>

                            {/* Portfolio Name */}
                            <b>{dashboardMetrics?.portfolioinformation?.name}</b>

                            {/* Porfolio Description */}
                            <p className='Dashboard-Tile-Portfolio-Description'>
                              {dashboardMetrics?.portfolioinformation?.description}
                            </p>

                            {/* List of Projects */}
                            <div className='Dashboard-Tile-Portfolio-Projects'>
                              {
                                dashboardMetrics?.portfolioinformation?.projects.slice(0, 3).map((project, index) => (
                                  <label key={index} className="Portfolio-Card-Projects">{project}</label>
                                ))
                              }
                            </div>

                            {/* Project Total */}
                            {
                              dashboardMetrics?.portfolioinformation?.projects.length - 3 !== 0 &&
                              <p className='Dashboard-Tile-Portfolio-ProjectTotal'>
                                + {dashboardMetrics?.portfolioinformation?.projects.length - 3} more projects
                              </p>
                            }

                          </div>
                        )
                        :
                        "No data found."
                      }
                    ></DashboardTile> 

                    {/* ---------------------------- */}
                    {/*  Consumption                 */}
                    {/* ---------------------------- */}

                    <DashboardTile
                      name={"Consumption"}
                      icon={<img alt="Consumption_Icon" src={BillingIcon}></img>}
                      body={
                        <div>
                          View the total consumption for all the projects in this porfolio
                        </div>
                      }
                      footer={<div onClick={() => navigate("/consumption")}>View consumption</div>}
                    ></DashboardTile> 
                      
                  </div>
            
                  {/* Column 2 */}
                  <div className="Dashboard-Col2">

                    {/* ---------------------------- */}
                    {/*  Security                    */}
                    {/* ---------------------------- */}

                      <DashboardTile
                        name={"Security"}
                        icon={<img alt="Security_Icon" src={SecurityIcon}></img>}
                        body={
                          <div className='Dashboard-Tile-Security-Tile'>
                            {
                              dashboardMetrics?.portfoliosecurityfindings ?
                              (
                                <>
                                
                                  {/* Critical Findings */}
                                  <div className='Dashboard-Tile-Security-Finding-Summary'>
                                    <div>
                                      <span className="Dot" style={{backgroundColor:" #C6221E"}}></span>
                                      Critical Findings 
                                    </div>
                                    {dashboardMetrics?.portfoliosecurityfindings?.critical ? dashboardMetrics?.portfoliosecurityfindings?.critical : 0}
                                  </div>

                                  {/* High Findings */}
                                  <div className='Dashboard-Tile-Security-Finding-Summary'>
                                    <div>
                                      <span className="Dot" style={{backgroundColor:" #EA4335"}}></span>
                                      High Findings 
                                    </div>
                                    {dashboardMetrics?.portfoliosecurityfindings?.high ? dashboardMetrics?.portfoliosecurityfindings?.high : 0}
                                  </div>

                                  {/* Medium Findings */}
                                  <div className='Dashboard-Tile-Security-Finding-Summary'>
                                    <div>
                                      <span className="Dot" style={{backgroundColor:" #FA7B17"}}></span>
                                      Medium Findings 
                                    </div>
                                    {dashboardMetrics?.portfoliosecurityfindings?.medium ? dashboardMetrics?.portfoliosecurityfindings?.medium : 0}
                                  </div>

                                  {/* Low Findings */}
                                  <div className='Dashboard-Tile-Security-Finding-Summary'>
                                    <div>
                                      <span className="Dot" style={{backgroundColor:" #F9AB00"}}></span>
                                      Low Findings 
                                    </div>
                                    {dashboardMetrics?.portfoliosecurityfindings?.low ? dashboardMetrics?.portfoliosecurityfindings?.low : 0}
                                  </div>

                                  {/* Other Findings */}
                                  <div className='Dashboard-Tile-Security-Finding-Summary'>
                                    <div>
                                      <span className="Dot" style={{backgroundColor:" #BDC1C7"}}></span>
                                      Other Findings 
                                    </div>
                                    {dashboardMetrics?.portfoliosecurityfindings?.other ? dashboardMetrics?.portfoliosecurityfindings?.other : 0}
                                  </div>

                                </>
                              )
                              :
                              (
                                <p>No data found.</p>
                              )
                            }
                          </div>
                        }
                        footer={<div onClick={() => navigate("/security")}>View security findings</div>}
                      ></DashboardTile>   

                  </div>
                  
                  {/* Column 3 */}
                  <div className="Dashboard-Col3">

                    {/* ---------------------------- */}
                    {/*  Sustainability              */}
                    {/* ---------------------------- */}
                    
                    <DashboardTile
                      name={"Sustainability"}
                      icon={<img alt="Sustainability_Icon" src={SustainabilityIcon}></img>}
                      body={
                        <div className='Dashboard-Tile-Sustainability-Tile'>
                          Project with the Highest Carbon Footprint
                          <p>
                            {
                              dashboardMetrics?.portfolioSustainabilityProject ? dashboardMetrics?.portfolioSustainabilityProject ? dashboardMetrics?.portfolioSustainabilityProject : "-" : "No data found."
                            }
                          </p>
                        </div>
                      }
                      footer={
                        <div onClick={() => navigate("/sustainability")}>View monthly emissions</div>
                      }
                    ></DashboardTile>  
                    
                  </div>
                </>
              )
              :
              null
            }
            
          </div>
        }

        /* ---------------------------- */
        /*  Welcome Page                */
        /* ---------------------------- */

        successContent={
  
            <div className="WelcomePage-Container">
      
              <div className="WelcomeHeader"> 
                <img  style={{marginRight: "15px"}} src={CloudIcon} alt="Cloud Icon"></img>          
                <h3>Welcome to Lendlease Cloud Portal</h3>                       
              </div>
      
              <div className="WelcomeBody">
                
                <p><button onClick={()=> navigate("/projects/create")}>Create</button> or select a project to get to your cloud destination. Learn more about <button onClick={()=> navigate("/gettingstarted")}>getting started</button>.</p>
                <p>The Lendlease Cloud Portal, is our portal to <a  target='_blank' rel='noopener noreferrer' href ="https://cloud.google.com/why-google-cloud">Google Cloud.</a> </p>
                <p>It is the start of your Google Cloud Journey where you can: </p>
                <ul>
                <li>Create and manage Google Cloud projects</li>
                <li>Gain security, financial and sustainability insights</li>
                <li>Connect to the Lendlease network</li>
                <li>Self-serve firewall rules, DNS zones, certificates</li>
                </ul>
              </div>
              
              <div className="QuickAccess">
                <h3>Quick Access</h3>

                {/* Knowledge Hub*/}
                <button onClick={() => {navigate("/knowledgehub/query=all/category=knowledge")}}> 
                    <img style={{margin: "-3px 15px -10px 10px", width:"15%"}} src={BookIcon} alt="BookIcon"/>
                    Knowledge Hub 
                </button>

                {/* Cloud Guild*/}
                <button>
                  <img style={{margin: "-3px 15px -10px 10px", width:"15%"}} src={CloudIcon} alt="CloudIcon"/>
                    <a href="https://neb.fyi/theguild" target="_blank" rel="noopener noreferrer" className="Link-Content-GettingStarted" type="button">
                    Cloud Guild
                    </a>
                </button>

                {/* Contact Us*/}
                <button onClick={() => {navigate("/contactus")}}> 
                    <img style={{margin: "-3px 15px -10px 10px", width:"15%"}} src={ContactUs} alt="ContactUs"/>
                    Contact Us 
                </button>                
              </div>
            </div>
          }   
      ></PageComponent>
    )
}
