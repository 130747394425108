//Libraries
import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";

//Global Store
import UserContextController from './Library/UserContextController';

//Components
import NavBar from './Components/NavBar/navbar'
import Sidebar from './Components/Sidebar/sidebar';

//Functions
import WriteUsageMetric from './Library/WriteUsageMetric';

//Nav Pages
import Home from './Pages/Home/Home';
import Portfolios from './Pages/Portfolios/Portfolios';
import CreatePortfolio from './Pages/Portfolios/CreatePortfolio';
import EditPortfolio from './Pages/Portfolios/EditPortfolio';
import DeletePortfolio from './Pages/Portfolios/DeletePortfolio';
import Projects from './Pages/Projects/Projects';
import ProjectCreate from './Pages/Projects/ProjectCreate/ProjectCreate';
import ProjectOwnershipTransfer from './Pages/Projects/ProjectOwnershipTransfer/OwnershipTransfer';
import ProjectOwnershipTransferCreate from './Pages/Projects/ProjectOwnershipTransfer/OwnershipTransferCreate';
import ProjectOwnershipTransferAccept from './Pages/Projects/ProjectOwnershipTransfer/AcceptOwnershipTransfer';
import ProjectOwnerLookup from './Pages/ProjectOwnerLookup/projectownerlookup';
import ModifyProjectOwnerLookup from './Pages/ProjectOwnerLookup/modifyprojectownerlookup';
import ProjectGroups from './Pages/Projects/ProjectGroups/projectgroups';
import CreateProjectGroups from './Pages/Projects/ProjectGroups/createprojectgroups';
import ModifyProjectGroups from './Pages/Projects/ProjectGroups/modifyprojectgroups';
import EditBudgetAndCost from './Pages/Projects/EditBudgetAndCost/editbudgetandcost';
import ProjectDelete from './Pages/Projects/ProjectDelete/ProjectDelete';
import SharedVPC from './Pages/SharedVPC/SharedVPC';
import SharedVPCAttach from './Pages/SharedVPC/SharedVPCAttach';
import SharedVPCManage from './Pages/SharedVPC/SharedVPCManage';
import Firewall from './Pages/Firewall/Firewall';
import FirewallManagement from './Pages/Firewall/FirewallManagement';
import FirewallRule from './Pages/Firewall/FirewallRule';
import DNS from './Pages/DNS/DNS';
import DNSRequest from './Pages/DNS/DNSRequest';
import Security from './Pages/Security/Security';
import Billing from './Pages/Billing/Billing';
import Consumption from './Pages/Consumption/Consumption';
import Sustainability from './Pages/Sustainability/Sustainability';
import NotFound from './Pages/NotFound/NotFound';
import GettingStarted from './Pages/GettingStarted/GettingStarted';
import Support from './Pages/Support/Support';
import KnowledgeHub from './Pages/Support/KnowledgeHub';
import Budgets from './Pages/Budgets/Budgets';
import ContactUs from './Pages/Support/ContactUs';

//CSS
import './App.css'



export default function App() {

  //------------------------------------------------------
  //  Define useStates 
  //------------------------------------------------------

    //Used to determine if the sidebar is visibile
    const [sidebarOpen, setSidebarOpen] = useState(true);

  //------------------------------------------------------
  //  Functions 
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects 
  //------------------------------------------------------


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  return (

    //------------------------------------------------------
    //  Main App 
    //------------------------------------------------------

      <div className={sidebarOpen ? ("Wrapper"):("Wrapper-NoSidebar")}>

        {/* ------------------ */}
        {/* Side Bar           */}
        {/* ------------------ */}
        <Sidebar sidebarStyle={sidebarOpen ? ("Sidebar"):("NoSidebar")} supportButtonStyle={sidebarOpen ? ("Support-Button"):("NoSidebar")}></Sidebar>

        {/* ------------------ */}
        {/* Navigation Header  */}
        {/* ------------------ */}

        <NavBar navStyle={sidebarOpen ? ("NavBar"):("NavBar-NoSidebar")} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></NavBar>

        {/* ------------------ */}
        {/* Router & Pages     */}
        {/* https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md#add-some-routes */}
        {/* https://stackoverflow.com/questions/65425884/react-router-v6-error-useroutes-may-be-used-only-in-the-context-of-a-route */}
        {/* ------------------ */}

        <div className={sidebarOpen ? ("PageContent"):("PageContent-NoSidebar")}>

          {/* Background Components that DO NOT return any HTML */}
          <UserContextController></UserContextController>
          <WriteUsageMetric></WriteUsageMetric>

          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/portfolios" element={<Portfolios/>} />
            <Route path="/portfolios/create" element={<CreatePortfolio/>} />
            <Route path="/portfolios/edit" element={<EditPortfolio/>} />
            <Route path="/portfolios/delete" element={<DeletePortfolio/>} />
            <Route path="/support" element={<Support/>} />
            <Route path="/knowledgehub/query=:query/category=:category" element={<KnowledgeHub/>}/>
            <Route path="*" element={<NotFound/>}></Route>

            <Route path="/projects" element={<Projects/>} />
            <Route path="/projects/create" element={<ProjectCreate/>} />
            <Route path="/projects/ownershiptransfer" element={<ProjectOwnershipTransfer/>} />
            <Route path="/projects/ownershiptransfer/create" element={<ProjectOwnershipTransferCreate/>} />
            <Route path="/projects/ownershiptransfer/accept" element={<ProjectOwnershipTransferAccept/>} />
            <Route path="/projects/projectcreate" element={<ProjectCreate/>} />
            <Route path="/projects/projectgroups" element={<ProjectGroups/>} />
            <Route path="/projects/createprojectgroups" element={<CreateProjectGroups/>} />
            <Route path="/projects/modifyprojectgroups" element={<ModifyProjectGroups/>} />
            <Route path="/projects/editbudgetandcost" element={<EditBudgetAndCost/>} />
            <Route path="/projectownerlookup/view" element={<ProjectOwnerLookup/>} />
            <Route path="/projectownerlookup/modify" element={<ModifyProjectOwnerLookup/>} />
            <Route path="/projects/projectdelete" element={<ProjectDelete/>} />
            <Route path="/sharedvpc" element={<SharedVPC/>} />
            <Route path="/sharedvpc/attach" element={<SharedVPCAttach/>} />
            <Route path="/sharedvpc/manage" element={<SharedVPCManage/>} />
            <Route path="/firewall" element={<Firewall/>} />
            <Route path="/firewall/management" element={<FirewallManagement/>} />
            <Route path="/firewall/rule" element={<FirewallRule/>} />
            <Route path="/dns" element={<DNS/>} />
            <Route path="/dns/request" element={<DNSRequest/>} />
            <Route path="/security" element={<Security/>} />
            <Route path="/billing" element={<Billing/>} />
            <Route path="/consumption" element={<Consumption/>} />
            <Route path="/budgets" element={<Budgets/>} />
            <Route path="/sustainability" element={<Sustainability/>} />
            <Route path="/gettingstarted" element={<GettingStarted/>} />
            <Route path="/contactus" element={<ContactUs/>} />
            
          </Routes>

        </div>
      </div>

      //------------------------------------------------------
  );
}