//================================================================
//  Manage Access to Shared VPC Network Form
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import QuestionLabel from '../../Components/QuestionLabel'
import InlineInputField from '../../Components/InlineInputField/inlineinputfield'
import ErrorMessageLabel from '../../Components/ErrorMessageLabel'
import ListItem from '../../Components/ListItem/listitem'
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';

//Functions
import ReactBackend from '../../Library/reactBackend'
import GetDocument from '../../Library/GetDocument';

//CSS
import './SharedVPCManage.css';

//Images
import PrideCat from '../../Components/Images/PrideCat.png';
import GrumpyFatal from '../../Components/Images/GrumpyCat.png';


function SharedVPCManage(){

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);
    var projectId = getUser?.preferences?.globalSelector?.selectedResource

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Define Variables
  //------------------------------------------------------
  
    var accStructureText = " Must contain 6-30 lowercase letters, numbers or hyphens, followed by '@', then lowercase letters, numbers, hyphens or full stops (.)"

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'accessdenied', 'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [formStatus, setFormStatus] = useState('onload');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Used to hold the service account field inputs
    const [value, setValue] = useState("");

    //Used to store the list of items displayed on the page
    const [items, setItems] = useState([]); 

    //Used to store the list of 'initial' items passed as props
    const [initialItems, setinitialItems] = useState([]); 

    //Used to store the list of service accounts to be granted VPC access
    const [serviceAccounts, setServiceAccounts] = useState([])

    //Used to store the error visibility state of the input field
    const [errorVisible, setErrorVisible] = useState(false); 

    //Used to store the error message for the input field
    const [errorMessage, setErrorMessage] = useState("Please enter a valid service account ID, e.g. exampleaccount@myproject.iam.gserviceaccount.com"); 
    
    //Used to determine if the 'Submit' button is disabled
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  
  //------------------------------------------------------
  //  Define Functions > Adding/Removing Items
  //------------------------------------------------------

    //Function for adding an item to the list
    const addItem = serviceAccount => {

      //Check for duplicate items
      var duplicateItems = items.filter(item => item.account === serviceAccount && (item.type === 'new' || item.type === 'existing'))
      var duplicateDeletedItem = items.filter(item => item.account === serviceAccount && item.type === 'deleted')

      //---VALIDATION CHECKS--//
      //No value provided > Set 'errorVisible' to true 
      if(serviceAccount.length <= 0){
        setErrorMessage("Please enter a service account email address and click the 'Add' button below.")
        setErrorVisible(true)
          
      //Invalid format > Set 'errorVisible' to true
      } else if(!isValidServiceAccount(serviceAccount)){
          setErrorVisible(true);
          setErrorMessage("Invalid service account email address." + accStructureText);
      
      //Check for duplicate accounts 
      } else if(duplicateItems.length > 0){
        setErrorVisible(true);
        setErrorMessage("This account has already been added to the list above.");
      
      //Default border and error visibility
      } else {
          setErrorVisible(false);

          //Change type for deleted items to 'new' - if re-added to the list
          if(duplicateDeletedItem.length > 0){
            let updatedItems = [...items];
            let index = items.findIndex(item => item.account === serviceAccount)
            updatedItems[index] = {...updatedItems[index], type: 'new'}
            setItems(updatedItems)
          
          //Add item to list
          } else {
            //Prepare service account object
            let serviceAccountObj = {
              "type": "new",
              "account": serviceAccount
            }
            
            //Add item to list
            const newItems = [...items, serviceAccountObj]
            setItems(newItems)
            }

          //Reset value to blank
          setValue("");
      }

    };

    //Function for removing the item from the list
    const removeItem = index => {

        //Change the type to 'deleted' in items
        let updatedItems = [...items];
        updatedItems[index] = {...updatedItems[index], type: 'deleted'}

        //Update display items list
        setItems(updatedItems)

    };

    //Function for re-adding the item to the list
    const readdItem = index => {

      //Change the type to 'new' in items
      let updatedItems = [...items];
      updatedItems[index] = {...updatedItems[index], type: 'new'}
  
      //Update display items list
      setItems(updatedItems)
  
    };

  //------------------------------------------------------
  //  API Calls
  //------------------------------------------------------

    //Gets project SVPC attachment data from Firestore
    async function getProjectVPCMetadata(selectedResource){

      //Set form status to 'pending'
      setFormStatus('pending')
                    
      //Fetch project info
      const queryResponse = GetDocument("projects", selectedResource)

      return Promise.resolve(queryResponse)
      .then((results) => {

        //Define arrayOfAccountIds (empty array)
        var arrayOfAccountIds = [];

        //If accounts exist > prepare array of accounts
        if(String(typeof(results.sharedvpcaccounts)) !== 'string'){

          var attachedServiceAccountIds = results.sharedvpcaccounts;
          
          //Loop through accountIDs and add 'type' property, i.e. 'new' or 'deleted' account
          attachedServiceAccountIds.forEach(element => {
            
            var obj = {
              "type": "existing",
              "account": element
            }

            arrayOfAccountIds.push(obj)

          });

        }

        //Set API Response
        setItems(arrayOfAccountIds);
        setinitialItems(attachedServiceAccountIds);
        setServiceAccounts(initialItems)

        // Check the project is attached & then set form status
        if (results.sharedvpcattached === 'false'){
          setFormStatus('error-other');

        } else{
          setFormStatus('onload');

        }

      })
      .catch((error) =>{
        console.log(`'getProjectVPCMetadata' failed to complete. Error Message: ${error.message}`);
        setAppErrors(`'getProjectVPCMetadata' failed to complete. Error Message: ${error.message}`);

        //Set status to 'error-fatal'
        setFormStatus('error-fatal');

      })

    }

  //------------------------------------------------------
  //  Define Functions > Field Validation (Regex)
  //  * - Paste Regex in 'https://regexr.com/' for explanation
  //------------------------------------------------------

    //Function to validate the target service account ID
    function isValidServiceAccount(ServiceAccount){  
      if (/^[a-z0-9][-a-z0-9]{5,29}@([a-z0-9][-.a-z0-9]*)?[a-z0-9]$/.test(ServiceAccount)) {  
        return (true)  
      }  
      return (false)  
    }

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------

    //Used to call "submitSharedVPCAccessRequest" > Edit VPC access for each service account
    const callAPI = async () => {

      //Set formStatus to 'pending'
      setFormStatus('pending');

      var requestBody = {
        "projectId": projectId,
        "serviceAccounts": serviceAccounts
      }

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('submitSharedVPCAccessRequest', requestBody)

      //Switch on formStatus
      if(response.status === 'success'){
            
        //Set formStatus to 'success'
        setFormStatus('success')

      } else if(response.status === 'error-invalid'){

        //Set formStatus to 'error-invalid'
        setAppErrors(response?.responseBody?.message);
        setFormStatus('error-invalid')

      } else if(response.status === 'error-fatal'){
          
        //Set formStatus to 'error-fatal'
        setFormStatus('error-fatal')

      } else if(response.status === 'error-timeout'){
          
        //Set formStatus to 'error-timeout'
        setFormStatus('error-timeout')

      }

      return response
    }   

  //------------------------------------------------------
  //  Event Handler > Service Account Form Submission & Field Changes
  //------------------------------------------------------

    //Event Handler for form submission
    const handleSubmit = e => {

        //Prevent refresh
        e.preventDefault();

        //Add item
        addItem(value);
    }

    //Event Handler for field changes 
    const handleChange = evt => {

        const newValue = evt.target.value;

        //No value provided > Set 'errorVisible' to false 
        if(newValue.length <= 0){
            setErrorVisible(false)
            
        //Invalid characters > Set 'errorVisible' to true
        } else if(!isValidServiceAccount(newValue)){
            setErrorVisible(true);
            setErrorMessage("Invalid service account email address." + accStructureText);
        
        //Default border and error visibility
        } else {
            setErrorVisible(false);
        }

        //Set value to input values 
        setValue(newValue);
    };

  //------------------------------------------------------
  //  Event Handler > Parent Form Submission
  //------------------------------------------------------

    //Event handler when form is submitted > validate all fields
    const eventHandlerOnSubmit = e => {

      //This prevents the form from been submitted
      var preventSubmit = false;

      //Check for deleted items in the list
      var deletedItems = items.filter(item => item.type === 'deleted')

      //If the 'add email' box has a value, warn user to click the '+ Add' button first
      if(value !== "") {
        setErrorMessage("Please click the '+ Add' button below to add this service account prior to submitting request.")
        setErrorVisible(true)

        //Prevent submission
        preventSubmit = true;
      }
      
      //Check if there is at least one service account added to the list > ONLY if there are no 'deleted' items in the list 
      if(serviceAccounts.length <= 0 && deletedItems <= 0){
        setErrorMessage("Please enter at least one service account email address and click the 'Add' button below.")
        setErrorVisible(true)
        
        //Prevent submission
        preventSubmit = true;

      //If there are no changes > prevent submission
      } else if(String(initialItems) === String(serviceAccounts)){

        setErrorMessage("No changes have been made. Please edit your service accounts above.")
        setErrorVisible(true)
        
        //Prevent submission
        preventSubmit = true;
      }

      //Allow the form to be submitted
      if (preventSubmit === false){
        setFormStatus('pending')
        e.preventDefault();
        callAPI()
      }

      //Prevent reload
      e.preventDefault();
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When the items are added/removed > add to list of service accounts
    useEffect(() => {

      //Filter items to extract 'new' and 'existing' items only
      var newExistingItems = items.filter(item => (item.type === 'new' || item.type === 'existing'))

      //Extract only the account values and push to a new array
      var newExistingAccounts = []

      newExistingItems.forEach(element => {
        
        newExistingAccounts.push(element.account)

      });

      //Set 'serviceAccounts' to the 'newExistingItems'
      setServiceAccounts(newExistingAccounts)
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[items])

    //If changes are made > Enable 'Submit' button 
    useEffect(() => {

      if(String(initialItems) === String(serviceAccounts)){
        setSubmitButtonDisabled(true)
    
      } else {
        setSubmitButtonDisabled(false)
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceAccounts])

    //Load the page when getUser is defined
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      //Call the functions
      getProjectVPCMetadata(getUser?.preferences?.globalSelector?.selectedResource)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser])

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={true}
        requireSelectedViews={['project']}
        requiredRoles={['projectOwner', 'projectOperator']}
        requiredEnvironments={['Standard']}
        status={formStatus}
        header={
          <PageHeader
            title={'Manage Access to the Shared VPC Network'}
            body={
              <div>
                <b>Please Note: </b>  
                  <ul>
                    <li>You must provide at least one deployment account which will be used to add compute resources to the Shared VPC network below.</li>
                    <li>The listed service account(s) will be attached to all existing subnets on the Shared VPC network.</li>
                  </ul>

                  For more information, refer to <DocumentLink docId="1401389196" title="Shared VPC - How to Get Started"></DocumentLink>.

              </div>  
            }
          ></PageHeader>
        }
        breadcrumb={
          {
            'name': 'Shared VPC', 
            'route': '/sharedvpc'
          }
        }
        body={
          <div className="Form-Container">
            <form className="Form-Body" id="attachsharedvpcform" onSubmit={eventHandlerOnSubmit}>

              {/*------------------------------------------------------------------ */}
              {/*   Service Accounts                                                */}
              {/*------------------------------------------------------------------ */} 

              {/* Service Account Summary */}
              <div className="list-container">

                {/* List of Service Accounts */}
                <div className="items">
                    <p style={{fontSize: "15px", lineHeight: "1.6"}}>Please provide a list of the service account(s) in the Google project <strong>{projectId}</strong>, you would like to grant access to the Shared VPC network below. Once complete, click 'Submit' below.</p>

                    <QuestionLabel question="Service Accounts"></QuestionLabel>

                    {/* If items exist > render a list  */}
                    {items.length > 0 ? 
                      (
                        items.map((item, index) => (
                          <ListItem
                              key={item.account}
                              item={item.account}
                              index={index}
                              removeItem={removeItem}
                              readdItem={readdItem}
                              type={item.type}
                          />
                        ))
                      ) :
                      (
                        <p><em>No service accounts attached.</em></p>
                      )
                    }
                </div>
                
                <QuestionLabel question="Enter a Service Account email address and click the '+ Add' button below." helpText = {"Enter an existing service account email address from your selected service project." + accStructureText}></QuestionLabel>

                {/* Add Service Account Form */}
                <div className="create-item" >
                    <form onSubmit={handleSubmit}>

                      <InlineInputField
                          //Inline Input Field Properties
                          placeholder={"e.g. account123@" + projectId + ".iam.gserviceaccount.com"}
                          value={value}
                          position = "right"
                          errorVisible={errorVisible}
                          inputFieldLength={"longinputfield"}
                              
                          //Function Properties
                          onChange={handleChange}
                      >
                      </InlineInputField>

                      {/* Error Message - Invalid Service Account ID  */}
                      <ErrorMessageLabel errorMessage={errorMessage} errorVisible={errorVisible}></ErrorMessageLabel>

                      <br></br>

                      {/* Add Button */}
                      <input className="add-account-button" defaultValue="+ Add" onClick={handleSubmit}></input>
                    </form>
                </div>

              </div>

            {/*------------------------------------------------------------------ */}
            {/*   Submit Button                                                   */}
            {/*------------------------------------------------------------------ */} 

              <div className='Button-Group'>
                <input className="Primary-Button" disabled={submitButtonDisabled} type="submit" value="Submit"></input>
                <button className='Secondary-Button' onClick={() => navigate('/sharedvpc')}>Cancel</button>
              </div>  

            {/*------------------------------------------------------------------ */}   

            </form>
        </div>
        }
        successContent={
          <div className='PageComponent-Errors-Container'>
            <img style={{width: "35%"}} src={PrideCat} alt="happy cat"/>
            <h2>Success!</h2>
            <p> 
              Your request to manage your access to the Shared VPC network is complete..
            </p>
          </div>
        }
        errorOtherContent={
          <div className='PageComponent-Errors-Container'>
            <img alt="Error Other" src={GrumpyFatal}></img>
            <h2>Oops something went wrong</h2>
            <p>
              An error occurred while we processed your request.
            </p>
            <p> 
              <b>Message:</b> The project <i>{projectId}</i> is not attached yet, return to the main Shared VPC page to manage attachments - Other Error.
            </p>
          </div>
        }
      ></PageComponent>
    );
  
  //------------------------------------------------------
}

export default SharedVPCManage;