
//================================================================
//  Page: Create Ownership Transfer
//================================================================

// Libraries
import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Functions
import ReactBackend from '../../../Library/reactBackend';
import GetDocument from '../../../Library/GetDocument';
import WriteDocument from '../../../Library/WriteDocument';
import CheckUserRole from '../../../Library/checkUserRole';

// Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import QuestionLabel from '../../../Components/QuestionLabel';
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel/index';
import ProjectSelectorComponent from './ProjectSelectorComponent';
import InvitePassportForm from './InvitePassportForm';

//Images
import PrideCat from '../../../Components/Images/PrideCat.png';

// CSS Styling
import './OwnershipTransfer.css'

export default function OwnershipTransferCreate() {

  //------------------------------------------------------
  //  Contexts & React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Role Check
  //------------------------------------------------------

    const csAdmin = CheckUserRole(['portalAdmin'], getUser?.profile?.roles, 'true');

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Status of the component > 'onload', 'pending',  'success', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    //Two useStates that holds the 'all' and the users 'selected' projects
    const [projects, setProjects] = useState([]);

    //Allows CS Admins to raise transfers on behalf of others
    const [requester, setRequester] = useState(getUser?.profile?.emailaddress);
    const [requestorError, setRequestorError] = useState('');

    //Holds the current previous project owner provided
    //via the projectSelectorComponent
    const [previousOwner, setPreviousOwner] = useState('');

    //Holds the new owners email address and any related errors
    const [newOwner, setNewOwner] = useState('');
    const [newOwnerError, setNewOwnerError] = useState('');

    const [serviceNowRef, setServiceNowRef] = useState('');

    //Holds document that will be created in firestore
    //This could be passed down to 'InvitePassportForm', if the user requires an invite
    const [newDocument, setNewDocument] = useState();

    //Holds if the user can submit the form
    const [projectErrors, setProjectErrors] = useState('');

    // Stores the current selected project in the project selector
    const [selectedProject, setSelectedProject] = useState();

    // Stores all the projects to be transferred
    const [allProjectsToTransfer, setAllProjectsToTransfer] = useState([]);

    // Stores all the available projects to be transferred to new project owner
    const [allAvailableProjects, setAvailableProjects] = useState([]);

    // Used to disable the add project to transfer button
    const [addProjectButtonDisabled, setAddProjectButtonDisabled] = useState(true);

    // Used to disable the remove project to transfer button
    const [removeProjectButtonDisabled, setRemoveProjectButtonDisabled] = useState(true);
    

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Handles submitting the form, checks it is completed
    function handleSubmit(){

      let preventSubmit = false;

      //Validate 'allProjectsToTransfer'
      if (allProjectsToTransfer?.length === 0) {

        setProjectErrors('Select at least one project');
        preventSubmit = true;

      } else {

        setProjectErrors('');
  
      }

      //Email address regex
      const regexPattern = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

      //Validate 'newOwner' emailaddress
      const newOwnerIsValid = regexPattern.test(newOwner);
      const newOwnerDomain = newOwner.split('@')[1];
      if(newOwner.length > 0 && newOwnerDomain !== undefined && newOwnerDomain.toLowerCase() !== 'lendlease.com'){

        setNewOwnerError('Only Lendlease employees can own GCP projects');
        preventSubmit = true;

      } else if(!newOwnerIsValid){
  
        setNewOwnerError('Enter a valid email address');
        preventSubmit = true;

      } else if(newOwner.length === 0){
  
        setNewOwnerError('Email address is required');
        preventSubmit = true;

      } else if(newOwner === requester && csAdmin === false){

        setNewOwnerError('You already own these projects...');
        preventSubmit = true;

      } else {
        
        setNewOwnerError('');

      }

      //Validate 'requester' emailaddress
      const requestorIsValid = regexPattern.test(requester);
      const requestorDomain = requester.split('@')[1];
      if(requester.length > 0 && requestorDomain !== undefined && requestorDomain.toLowerCase() !== 'lendlease.com'){

        setRequestorError('Only Lendlease employees can own GCP projects');
        preventSubmit = true;

      } else if(!requestorIsValid){

        setRequestorError('Enter a valid email address');
        preventSubmit = true;

      } else if(requester.length === 0){

        setRequestorError('Email address is required');
        preventSubmit = true;

      } else {

        setRequestorError('');

      }

      //------------------------------------------------------
      //  Prevent Submit Check
      //------------------------------------------------------

      if (preventSubmit) {

        return;

      } else {

        //There should always be a previous owner when request is submitted
        if(previousOwner?.length === 0) {
          setPageStatus('error-fatal');
          return;
        }

        //Passed all validations > set form to pending
        setPageStatus('pending');

      }

      //------------------------------------------------------
      //  Check if the new owner exists in passport
      //  - Call the Backend API > 'passportPromise'
      //------------------------------------------------------

      const request = [
        newOwner.toLowerCase()
      ]

      const passportPromise = ReactBackend('getActiveUserCheck', request);

      //------------------------------------------------------
      //  Get all the project information
      //  - Loop through projects
      //  - Call Firestore > Push each 'promise' into 'arrayPromises'
      //------------------------------------------------------

      const arrayPromises = [];
      allProjectsToTransfer?.forEach((project) =>{
        const promise = GetDocument('projects', project);
        arrayPromises.push(promise)
      })

      const projectPromises = Promise.allSettled(arrayPromises);
      
      //------------------------------------------------------
      //  Get all the project information
      //  - Resolve 'passportPromise' & 'arrayPromises'
      //  - Add result to 'document'
      //------------------------------------------------------

      Promise.all([passportPromise, projectPromises])
      .then(async(results) => {

        //Tracks failures
        let failure = false;

        //Resolved promises
        const passportCheck = results[0];
        const projectDocs = results[1];

        //Function Variables
        let activePassportUser = false;

        //Check new Project owners passport status
        if(passportCheck.status === 'success'){
          activePassportUser = passportCheck.responseBody.message[newOwner.toLowerCase()];

        } else {
          console.log(`Failed to get users passport status. Error: ${passportCheck.responseBody.message}`);
          setAppErrors(`Failed to get users passport status. Error: ${passportCheck.responseBody.message}`);
          failure = true;
        }

        //Check new Project owners passport status
        const projects = [];
        projectDocs.forEach((promise) =>{

          if(promise.status === 'fulfilled'){
            projects.push({
              'projectid': promise?.value?.projectid,
              'projectcode': promise?.value?.projectcode,
              'tasknumber': promise?.value?.tasknumber,
              'expendituretype': promise?.value?.expendituretype
            })
          } 
          
          //Failed to resolve promise > reject submission!
          else {
            console.log(`Unable to get project information from firestore. Error: ${promise.reason}`);
            setAppErrors(`Unable to get project information from firestore. Error: ${promise.reason}`);
            failure = true;
            return;
          }

        });

        //------------------------------------------------------
        //  Failure was found in the above promises, prevent submit!
        //------------------------------------------------------

        if(failure) {
          setPageStatus('error-fatal');
          return;
        }

        //------------------------------------------------------
        //  If the user needs to be invited > activePassportUser === false
        //  1. Create the firestore document
        //  2. Pass as a prop to 'InvitePassportForm'
        //  3. set the requestType to 'error-other'
        //------------------------------------------------------

        if(!activePassportUser) {

          setNewDocument({
            'transactionid': 'TBD',
            'inviterequired': true,
            'processid': 'TBD',
            'projects': projects,
            'previousowner': previousOwner,
            'newowner': newOwner.toLowerCase(),
            'servicenowref': serviceNowRef,
            'requester': requester.toLowerCase(),
            'creator': getUser?.profile?.emailaddress,
            'creationdate': new Date(),
            'status': 'pending'
            }
          )
          setPageStatus('error-other');
          return;
        }

        //------------------------------------------------------
        //  Get all the project information
        //  - Write to Firestore
        //------------------------------------------------------

        //Submit request
        const documentId = `txn-${Date.now()}`
        const document = {
          'transactionid': documentId,
          'inviterequired': false,
          'processid': 'TBD',
          'projects': projects,
          'previousowner': previousOwner,
          'newowner': newOwner.toLowerCase(),
          'servicenowref': serviceNowRef,
          'requester': requester.toLowerCase(),
          'creator': getUser?.profile?.emailaddress,
          'creationdate': new Date(),
          'status': 'pending'
        }
        return WriteDocument('project-owner-transfer', documentId, document, false).then(() =>{

          setPageStatus('success');

        });

      })
      .catch((error) =>{
        console.log(`Failed to submit invite form. Error: ${error}`);
        setAppErrors(`Failed to submit invite form. Error: ${error}`);
        setPageStatus('error-fatal');

      });
    }

    // Add a project to transfer
    function addProjectToTransfer(project){

      // Remove project from allAvailableProjects
      allAvailableProjects.splice(allAvailableProjects.indexOf(project), 1)
      setAvailableProjects([...allAvailableProjects])

      // Add project to allProjectsToTransfer
      allProjectsToTransfer.push(project)
      setAllProjectsToTransfer([...allProjectsToTransfer])

      // Reset selected project
      setSelectedProject(undefined);

    }

    // Function to add all projects to transfer
    function addAllProjectsToTransfer(){

      // Remove all projects from allAvailableProjects
      setAvailableProjects([]);

      // Add all projects to allProjectsToTransfer
      setAllProjectsToTransfer(allProjectsToTransfer?.concat(allAvailableProjects))

      // Reset selected project
      setSelectedProject(undefined);

    }

    // Remove a project to transfer
    function removeProjectToTransfer(project){

      // Remove project from allProjectsToTransfer
      allProjectsToTransfer.splice(allProjectsToTransfer.indexOf(project), 1)
      setAllProjectsToTransfer([...allProjectsToTransfer])

      // Add project to allAvailableProjects
      allAvailableProjects.push(project)
      setAvailableProjects([...allAvailableProjects])

      // Reset selected project
      setSelectedProject(undefined);

    }

    // Function to remove all projects to transfer
    function removeAllProjectToTransfer(){

      // Remove all projects from allProjectsToTransfer
      setAllProjectsToTransfer([]);

      // Remove all projects to allAvailableProjects
      setAvailableProjects(allAvailableProjects.concat(allProjectsToTransfer));

      // Reset selected project
      setSelectedProject(undefined);

    }
    
    // Update the selected project > Disable add/remove button
    function updateSelectedProject(project, action){

      setSelectedProject(project);

      if(action === 'add') {
        setAddProjectButtonDisabled(false);
        setRemoveProjectButtonDisabled(true);

      } else {
        setRemoveProjectButtonDisabled(false);
        setAddProjectButtonDisabled(true);

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When the list of projects changes > Update all available projects and projects to be transferred to new owner
    useEffect(()=>{

      setAvailableProjects(projects)
      setAllProjectsToTransfer([]);

    }, [projects]);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------
  
  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['organization']}
      requiredRoles={['lendleaseAccount']}
      requiredRolesValue={'true'}
      status={pageStatus}
      header={
        <PageHeader
          title={'Transfer Ownership of Your Project'}
          body={
            <>
              Let the incoming project owner know that you are initiating this request. They will receive an email prompting them to accept the request. Once they have accepted, they will be prompted to enter new billing information. 
            </>
          }
        ></PageHeader>
      }
      breadcrumb={{
        'name': 'Manage Project Ownership',
        'route': '/projects/ownershiptransfer'
      }}
      body={
        <div className='Form-Container'>

          {/* Projects */}
          <div className='Form-Body'>

            {/* Requestor */}
            <ProjectSelectorComponent
              projects={projects} 
              setProjects={setProjects}
              requester={requester}
              setRequester={setRequester}
              setServiceNowRef={setServiceNowRef}
              serviceNowRef={serviceNowRef}
              setValidPreviousOwner={setPreviousOwner}
            ></ProjectSelectorComponent>
            <ErrorMessageLabel errorMessage={requestorError} errorVisible={true}></ErrorMessageLabel>

            {/* Project Selector */}
            <div hidden={projects?.length <= 0}>

              {/* Label */}
              <QuestionLabel question="Select the projects you would like to transfer *"/>

              {/* Project Selector */}
              <div className="Portfolios-Projects-Editor-Container">
                
                {/* Header */}
                <div className="Projects-Available-Header">
                  Projects Available
                </div>

                <div className="Projects-Selector-Header"></div> 
                
                <div className="Projects-Added-Header">
                  Projects To Transfer
                </div>

                {/* Projects Available */}
                <div className="Projects-Available-Body">
                  {
                    allAvailableProjects?.sort().map((project, index) =>(
                      <div key={index} className={selectedProject === project ? 'Portfolio-Project-Label-Active': 'Portfolio-Project-Label'} onClick={() => updateSelectedProject(project, "add")}>
                        {project}
                      </div>
                    ))
                  }
                </div>
                
                {/* Selector Buttons*/}
                <div className="Projects-Selector-Body">
                  
                  {/* Add Button */}
                  <div className="button-tooltip">
                    <button className='Primary-Button' style={{padding: "5px 20px"}} disabled={selectedProject === undefined ? true : addProjectButtonDisabled} onClick={() => addProjectToTransfer(selectedProject)}> {'>'} </button>
                    <span className="tooltiptext"> Add project </span>
                  </div> 
                  
                  {/* Add All Button */}
                  <div className="button-tooltip">
                    <button className='Primary-Button' disabled={projects?.length === 0} style={{padding: "5px 20px"}} onClick={() => addAllProjectsToTransfer()}> {'>|'} </button>
                    <span className="tooltiptext"> Add all projects </span>
                  </div> 

                  {/* Remove Button */}
                  <div className="button-tooltip">
                    <button className='Primary-Button' style={{padding: "5px 20px"}} disabled={selectedProject === undefined ? true : removeProjectButtonDisabled}  onClick={() => removeProjectToTransfer(selectedProject)}> {'<'} </button>
                    <span className="tooltiptext"> Remove project </span>
                  </div> 

                  {/* Remove All Button */}
                  <div className="button-tooltip">
                    <button className='Primary-Button' disabled={allProjectsToTransfer?.length === 0} style={{padding: "5px 20px"}} onClick={() => removeAllProjectToTransfer()}> {'|<'} </button>
                    <span className="tooltiptext"> Remove all projects </span>
                  </div> 

                </div>
                
                {/* Projects Added */}
                <div className="Projects-Added-Body">
                  {
                    allProjectsToTransfer?.sort().map((project, index) =>(
                      <div key={index} className={selectedProject === project ? 'Portfolio-Project-Label-Active': 'Portfolio-Project-Label'} onClick={() => updateSelectedProject(project, "remove")}>
                        {project}
                      </div>
                    ))
                  }
                </div>

              </div>
            
            </div>

            {/* Error - No project selected */}
            <ErrorMessageLabel errorMessage={projectErrors} errorVisible={projectErrors?.length > 0}></ErrorMessageLabel>

            {/* New Project Owner Email */}
            <QuestionLabel question={"New Project Owner Email"} helpText = {"Enter a new Lendlease project owner email"}></QuestionLabel>
            <InlineInputField
              name='newowner'
              value={newOwner}
              placeholder='jane.smith@lendlease.com'
              position = "right"
              inputFieldLength={"mediuminputfield"}
              onChange={(event) => setNewOwner(event.target.value)}
            >
            </InlineInputField>
            <ErrorMessageLabel errorMessage={newOwnerError} errorVisible={true}></ErrorMessageLabel>

            {/* Submit Buttons & error message */}
            <div className='Button-Group'>
              <button className="Primary-Button" onClick={handleSubmit}>Submit</button>
              <button className="Secondary-Button" onClick={() => navigate('/projects/ownershiptransfer')}>Cancel</button>
            </div>

          </div>
        </div>
      }
      errorOtherContent={
        <div>
          <PageHeader
            title={'Create Passport Account'}
            body={"New project owners require a passport account, complete the below form to initiate the transfer."}
          ></PageHeader>
          <InvitePassportForm 
            setPageStatus={setPageStatus}
            newDocument={newDocument}
          ></InvitePassportForm>
        </div>
      }
      successContent={
        <div className='PageComponent-Errors-Container'>
          <img style={{width: "35%"}} src={PrideCat} alt="happy cat"/>
          <h2>Success!</h2>
          <p> 
            We have sent an email notifying the proposed new project owner of your request to transfer. 
            <br></br>
            They will be prompted to accept this request in the Lendlease Cloud Portal.         
          </p>
        </div>
      }
    ></PageComponent>
  )
}
