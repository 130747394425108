//================================================================
//  Component: PageComponent
//================================================================

//  Purpose:
//    1. Provides a standardised page container that handles styling
//    2. Prevents unauthorized access via a role check
//    3. Advises customers to choose a 'selectedResource'
//    4. Provides standardised 'status' for success pages, errors, etc

//  Properties:
//
//  <REQUIRED>
//    - header = Provide HTML content
//    - body = Provide HTML content
//
//  <OPTIONAL>
//    - requireSelectedViews = Provide an array of the required views to access this page, ['project', 'portfolio', 'global']
//    - requireSelectedResource = boolen that opens the global selector, IF 'selectedResource' is undefined
//    - requiredRoles = Provide an array of roles. Refer to the predefined 'roles' in the users collection
//    - requiredRolesValue = Provide a string, the user MUST have this value in ONE of the 'requiredRoles'
//    - requiredEnvironments = Provide an array of roles. Refer to the predefined 'allProjectsMetadata' in the  users collection
//    - status = Provide a useState of the page status, 'pending', 'successContent', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
//    - breadcrumb = Provide an Object with the 'name' and 'route', {'name': 'Firewall', 'route': '/firewall'}
//    - disclaimerContent = { HTML Content }
//    - successContent = Provide a HTML content for the successContent page
//    - errorOtherContent = Provide a HTML content for the error-other page

//  Example:
//
//    <PageComponent
//      header={ HTML Content }
//      body={ HTML Content }
//      requireSelectedViews={ ['project'] }
//      requireSelectedResource={ true }
//      requiredRoles={ ['lendleaseAccount'] }
//      requiredRolesValue={ 'true' }
//      requiredEnvironments={ ['Sandbox', 'Standard'] }
//      status={ 'success' }
//      breadcrumb={ 'firewall' }
//      disclaimerContent={ HTML Content }
//      successContent={ HTML Content }
//      errorOtherContent={ HTML Content }
//    ></PageComponent>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

//Contexts
import {GetUser, SetUser, GetAppErrors} from '../../Library/GlobalContexts';

//Components
import Breadcrumbs from '../Breadcrumbs/breadcrumbs';

//Functions

//Images
import UnauthorisedUser from '../Images/unauthorisedUser.svg';
import LoadingIcon from '../Images/Loading_Ripple.svg';
import GrumpyFatal from '../Images/GrumpyCat.png';
import ErrorFatal from '../Images/errorFatal.svg';
import InfoIcon from '../Images/Icon_Info_Black.svg';

//CSS is handled in index.css

export default function PageComponent({
  header,
  body,
  requireSelectedViews,
  requireSelectedResource,
  requiredRoles,
  requiredRolesValue,
  requiredEnvironments,
  status,
  breadcrumb,
  successContent,
  errorOtherContent,
  disclaimerContent,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const getAppErrors = useContext(GetAppErrors);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Handles what the user will see > 'pending', 'selectview', 'selectresource', 'accessdenied', 'invalidenvironment', 'complete' (catch all!)
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to prompt the user to select a view and resource
    function openSelectorModal(){

      //Open the page view
      if(requireSelectedViews?.length === 1) {

        // We want an 'organization' to default to 'lendlease.cloud'
        if (requireSelectedViews[0] === 'organization') {

          getUser.preferences.globalSelector.selectedView = 'organization';
          getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';
          getUser.preferences.globalSelector.visible = false;

          return setUser({...getUser});

        } 
        
        // Otherwise select the first view, none the resource
        getUser.preferences.globalSelector.selectedView = requireSelectedViews[0];
        getUser.preferences.globalSelector.selectedResource = 'none';

      };

      //Catch all > Show modal
      getUser.preferences.globalSelector.visible = true;

      //Update context
      setUser({...getUser});

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload, validate the 'selectedView' & 'selectedResource'
    useEffect(()=>{

      if (getUser.preferences === undefined) return;
      if (getUser.profile === undefined) return;
      if (getUser.profile.roles === undefined) return;

      // Extract the current view, resource and roles
      const selectedView = getUser.preferences.globalSelector.selectedView;
      const selectedResource = getUser.preferences.globalSelector.selectedResource;
      const userRoles = getUser.profile.roles;
      const allProjectsMetadata = getUser.profile.views.allProjectsMetadata;


      //------------------------------------------------------
      // Validate the selected view
      //------------------------------------------------------

      // If there is no selected view and there are no requiredSelectedViews specified > display message to ask the user to select a view
      if (requireSelectedViews !== undefined && requireSelectedViews?.length > 0) {

        // Exceptions for 'organization'
        if (requireSelectedViews?.length === 1 && requireSelectedViews[0] === 'organization' && selectedResource !== 'lendlease.cloud' && getUser.preferences.globalSelector.visible === false) {

          getUser.preferences.globalSelector.selectedView = 'organization';
          getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';
          setUser({...getUser});

        }

        //When the page view does not match the user's selected view > Show message
        else if (requireSelectedViews.includes(selectedView) === false) {
          
          return setPageStatus('selectview');
    
        }

      }


      //------------------------------------------------------
      // Validate selected resource
      //------------------------------------------------------

      // Exceptions for 'organization'
      // 
      if (requireSelectedViews?.length === 1 && requireSelectedViews[0] === 'organization' && selectedResource !== 'lendlease.cloud' && getUser.preferences.globalSelector.visible === false) {

        getUser.preferences.globalSelector.selectedView = 'organization';
        getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';
        setUser({...getUser});

      }  

      // If a selected resource is required and the resource is not selected > display message to ask the user to select a resource
      else if (requireSelectedResource && (selectedResource === '' || selectedResource === 'none')){
        
        return setPageStatus('selectresource');

      }


      //------------------------------------------------------
      // Validate users roles
      //------------------------------------------------------

      if (requiredRoles !== undefined && requiredRoles?.length > 0) {

        let hasRole = false;

        // Loop each of the provided roles
        requiredRoles?.forEach(role => {

          // Check if 'requiredRolesValue' OR 'selectedResource' exists in the provided roles
          if (requiredRolesValue === undefined) {
            
            if (userRoles[role]?.includes(selectedResource)) {

              hasRole = true;

            }

          } else {

            if (userRoles[role]?.includes(requiredRolesValue)) {

              hasRole = true;

            } 

          }

        });

        
        if (hasRole === false) {

          return setPageStatus('accessdenied');

        }

      }


      //------------------------------------------------------
      // Validate project environment
      //------------------------------------------------------

      if (selectedView === 'project' && requiredEnvironments !== undefined && requiredEnvironments?.length > 0) {

        let hasEnvironment = false;

        // Loop each of the provided roles
        requiredEnvironments?.forEach(env => {

          // Filter the array of projects, find the current 'selectedResource' project
          const project = allProjectsMetadata?.filter((project) => (project.projectid === selectedResource))[0]

          // Only 'selectedResource' with the correct env will pass this check
          if (project?.type === env) return hasEnvironment = true;

        });

        if (hasEnvironment === false) {

          return setPageStatus('invalidenvironment');

        }

      }


      //------------------------------------------------------
      // All validations are completed
      //------------------------------------------------------

      return setPageStatus('complete');


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //============================
    // Pending
    //============================

    if (pageStatus === 'pending'){
      return null;
    }

    //============================
    // No view selected
    //============================

    else if (pageStatus === 'selectview'){
      return (
        <div className='PageComponent-Container'>
    
          <div className='PageComponent-Header'>
            {header}
          </div>
    
          <div className='PageComponent-Body'>
            <div className='PageComponent-Label'>

              <div className='PageComponent-LabelMessage'>
                <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                <p>
                  Page not viewable. To view this page, select

                  {/* This is for Home.js welcome page. Welcome, Projects, Portfolios */}
                  { requireSelectedViews?.length === 3 && requireSelectedViews[0] === 'welcome' ? 
                    (
                    ` a ${requireSelectedViews[1]} or ${requireSelectedViews[2]}`
                    )
                    :requireSelectedViews?.length === 1 && requireSelectedViews[0] === 'organization' ?
                    (
                      ` an ${requireSelectedViews}`
                    )
                    : requireSelectedViews?.length === 1 ? 
                    (
                      ` a ${requireSelectedViews}`
                    )
                    : requireSelectedViews?.length === 2 ? 
                    (
                      ` a ${requireSelectedViews[0]} or ${requireSelectedViews[1]}`
                    )
                    :requireSelectedViews?.length === 3 ? 
                    (
                      ` a ${requireSelectedViews[0]}, ${requireSelectedViews[1]} or ${requireSelectedViews[2]}`
                    ) 
                    : 
                    (
                      null
                    )
                  }.
                </p>
              </div>
              
              <button className='PageComponent-SelectButton' onClick={() => openSelectorModal()}>SELECT RESOURCE</button>

            </div>
          </div>

        </div>
      )
    }

    //============================
    // No resource selected
    //============================

    else if (pageStatus === 'selectresource'){
      return (
        <div className='PageComponent-Container'>
    
          <div className='PageComponent-Header'>
            {header}
          </div>
    
          <div className='PageComponent-Body'>
            <div className='PageComponent-Label'>
              <div className='PageComponent-LabelMessage'>
                <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                <p>Page not viewable. To view this page, select a resource.</p>
              </div>

              <button className='PageComponent-SelectButton' onClick={() => openSelectorModal()}>SELECT RESOURCE</button>
            
            </div>

          </div>

        </div>
      )
    }
    
    //============================
    // Access denied > user doesn't have the correct role
    //============================

    else if (pageStatus === 'accessdenied'){
      return (
        <div className='PageComponent-Container'>
    
          <div className='PageComponent-Header'>

            {/* Breadcrumbs */}
            {
              breadcrumb !== undefined &&
                <Breadcrumbs
                  pageName={breadcrumb?.name}
                  pageRoute={breadcrumb?.route}
                  pageView={breadcrumb?.view}
                  pageResource={breadcrumb?.resource}
                ></Breadcrumbs>
            }

            {header}
          </div>
    
          <div className='PageComponent-Body'>
            <div className='PageComponent-AccessDenied'>

              <img alt="Error Fatal" src={UnauthorisedUser}></img>
              <div style={{fontSize: 'var(--fontsize-subheader)'}}>
                Sorry it seems like you do not have the necessary permissions to access this page. 
              </div>
              <br></br>
              If you feel this is a mistake, contact the Lendlease Cloud Team <a href = "https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96&sysparm_category=109f0438c6112276003ae8ac13e7009d" target="_blank" rel="noopener noreferrer"> here</a>.
            
            </div>
          </div>
    
        </div>
      )
    }

    //============================
    // invalid environment > project doesn't have the correct environment
    //============================

    else if (pageStatus === 'invalidenvironment'){

      return (
        <div className='PageComponent-Container'>
    
          <div className='PageComponent-Header'>

            {/* Breadcrumbs */}
            {
              breadcrumb !== undefined &&
                <Breadcrumbs
                  pageName={breadcrumb?.name}
                  pageRoute={breadcrumb?.route}
                  pageView={breadcrumb?.view}
                  pageResource={breadcrumb?.resource}
                ></Breadcrumbs>
            }

            {header}
          </div>
    
          <div className='PageComponent-Body'>
            <div className='PageComponent-Body'>
              <div className='PageComponent-Label'>
                <div className='PageComponent-LabelMessage'>
                  <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                  <p>Project <strong>{getUser?.preferences?.globalSelector?.selectedResource}</strong> does not support this feature, select another resource with the below type(s). </p>
                </div>

                <button className='PageComponent-SelectButton' onClick={() => openSelectorModal()}>SELECT RESOURCE</button>
              
              </div>

              {/* Supported environments that can access this page */}
              <ul>
                {
                  requiredEnvironments?.map((type) => (
                    <li>{type}</li>
                  ))
                }
              </ul>

            </div>
          </div>

        </div>
      )

    }

    //============================
    // All validation checks were passed, load the page!
    //============================

    else {

      return (
        <div className='PageComponent-Container'>

          <div className='PageComponent-Header'>
            
            {/* Breadcrumbs */}
            {
              breadcrumb !== undefined &&
                <Breadcrumbs
                  pageName={breadcrumb?.name}
                  pageRoute={breadcrumb?.route}
                  pageView={breadcrumb?.view}
                  pageResource={breadcrumb?.resource}
                ></Breadcrumbs>
            }

          </div>

          <div className='PageComponent-Body'>

            {

              //============================
              // Disclaimer page
              //============================

              status === 'disclaimer' ? (

                <>
                  {header}
                  {disclaimerContent}
                </>

              ):


              //============================
              // Pending page
              //============================

              status === 'pending' ? (

                <>
                  {header}
                  <div className='PageComponent-Errors-Container'>
                    <img alt="loading-circle-icon" src={LoadingIcon}></img>
                  </div>
                </>

              )
              

              //============================
              // Success page
              //============================

              : status === 'success' ? (
                <>
                  {header}
                  {successContent}
                
                </>

              )


              //============================
              // Error Other page
              //============================

              : status === 'error-other' ? (

                <>
                  {errorOtherContent}
                </>
                
              )


              //============================
              // Error-Invalid page
              //============================

              : status === 'error-invalid' ? (

                <div className='PageComponent-Errors-Container'>
                  <img alt="Error Fatal" src={GrumpyFatal}></img>
                  <h2>Oops something went wrong</h2>
                  <p>
                    An error occurred while we processed your request.
                    <br></br>
                    If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
                  </p>
                  <p > 
                    <b>Message:</b> {getAppErrors}
                  </p>
                  <Link to="/home">
                    <button className="form-submit-button">Return Home</button>
                  </Link>
                </div>

              )


              //============================
              // Error-Fatal or Error-Timeout page
              //============================

              : status === 'error-fatal' || status === 'error-timeout' ? (

                <div className='PageComponent-Errors-Container'>
                  <img alt="Error Fatal" src={ErrorFatal}></img>
                  <h2>Oops something went wrong</h2>
                  <p>
                    An error occurred while we processed your request.
                    <br></br>
                    If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
                  </p>
                  <p> 
                    <b>Message:</b> Failed to load the page - Fatal Error.
                  </p>
                  <Link to="/home">
                    <button className="form-submit-button">Return Home</button>
                  </Link>
                </div>

              )


              //============================
              // Catch all > show the default body
              //============================

              : status === 'onload' ? (

                <>
                  {header}
                  {body}
                </>

              ) : (

                <>
                  {header}
                  {body}
                </>

              )
            }
          </div>

        </div>
      )
    }
  
}
