//================================================================
//  Sustainability page
//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import * as moment from 'moment';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import SustainabilityResponse from './Components//sustainabilityresponse';
import ProjectEmissionsTable from './Components/projectemissionstable';
import ProjectEmissionsChart from './Components/projectemissionschart';
import UnattendedProjectsTable from './Components/unattendedprojectstable';
import ExportToCSV from '../../Components/ExportToCSV/ExportToCSV';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import GetDocument from '../../Library/GetDocument';

//Images
import SustainabilityIcon from '../../Components/Images/Leaf_Icon.svg';
import DownwardTrend from '../../Components/Images/DownwardTrend.svg';
import UpwardTrend from '../../Components/Images/UpwardTrend.svg';
import TableViewOutline from '../../Components/Images/TableView_Outline.svg';
import ChartViewOutline from '../../Components/Images/ChartView_Outline.svg';
import TableDisabled from '../../Components/Images/Table_Disabled.svg';
import ChartDisabled from '../../Components/Images/Chart_Disabled.svg';
import Tooltip from '../../Components/Images/Tooltip.svg';
import CO2 from '../../Components/Images/UPR_Co2.png';
import Lock from '../../Components/Images/UPR_Lock.svg';

//CSS
import './Sustainability.css';


export default function Sustainability() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Handles the onLoad errors > 'pending', 'onload', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Holds the array of project ids from the global store
    const [projectIDs, setProjectIDs] = useState([]);

    //Used to determine the current view of the emissions data > 'table', 'chart'
    const [view, setView] = useState('table');

    //Used to save the query function status > 'pending', 'success', 'error-invalid', 'error-fatal'
    const [sustainabilityQueryStatus, setSustainabilityQueryStatus] = useState('pending');

    //Used to save project emissions table data
    const [projectEmissionsTableData, setProjectEmissionsTableData] = useState();

    //Used to save project emissions chart data
    const [projectEmissionsChartData, setProjectEmissionsChartData] = useState([]);

    //Used to save the unattended projects table data
    const [unattendedProjectsTableData, setUnattendedProjectsTableData] = useState([]);

    //Used to save the summary data displayed at the top of the page
    const [summaryData, setSummaryData] = useState("");

    //Toggle for displaying unattended projects
    const [showUnattendedProjects, setShowUnattendedProjects] = useState(false);


  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Gets project emissions table data from Firestore
    async function getProjectEmissionsTableData(projects){

      //Get Months > Latest Month and Previous Month 
      return QueryDocument("month", "", ["month", "desc"], 2)
      .then((results) =>{

        //Prepare all project metrics 
        var allMetrics = {
          "latestMonth": results[0].month,
          "previousMonth": results[1].month,
          "latestMonthDisplayName": results[0].displayName,
          "previousMonthDisplayName": results[1].displayName,
          "projectEmissions": []
        } 

        const arrayOfProjectPromises = []

        //Loop through projects > Get Document  
        projects.forEach(project => {
                      
          //Get all project level emissions
          const results = QueryDocument(`sustainability/${project}/months`, "", ["month_int", "desc"], 2)
        
          //Append temp array with new promise
          arrayOfProjectPromises.push(results)

        });

        //Settled all promises in the array of promises
        return Promise.allSettled(arrayOfProjectPromises)
        .then((results) => {
          allMetrics.projectEmissions = results
          return allMetrics
        })


      })
      .catch((error) =>{
        console.log(`'getProjectEmissionsTableData' failed to complete. Error Message: ${error.message}`)
        setAppErrors(`'getProjectEmissionsTableData' failed to complete. Error Message: ${error.message}`)
        setSustainabilityQueryStatus('error-fatal')
      })

    }
  
    //Gets project emissions chart data from Firestore
    async function getProjectEmissionsChartData(projects){

      //Get Last 12 Months from 'month' collection
      return QueryDocument("month", "", ["month", "desc"], 12)
      .then((results) =>{

        //Prepare array of last 12 months 
        var arrayOfMonths = results.map(function(month) { return month.displayName }).reverse()

        //Prepare all project metrics 
        var allMetrics = {
          "months": arrayOfMonths,
          "projectEmissions": []
        } 

        //Loop through projects > Get Documents for the Last 12 Months  
        projects.forEach(project => {
                      
          //Get all project level emissions
          const results = QueryDocument(`sustainability/${project}/months`, "", ["month_int", "desc"], 12)
        
          //Append temp array with promise
          allMetrics.projectEmissions.push(results)

        });

        return allMetrics

      })
      .catch((error) =>{
        console.log(`'getProjectEmissionsChartData' failed to complete. Error Message: ${error.message}`)
        setAppErrors(`'getProjectEmissionsChartData' failed to complete. Error Message: ${error.message}`)
        setSustainabilityQueryStatus('error-fatal')
      })
    }

    //Extracts metrics for summary pane display at the top of the page
    function getSummaryData(tableData) {
  
      try {
        //Convert latest month to long format > e.g. 'Jan' to 'January'
        var latestMonth = moment(tableData.latestMonthDisplayName, 'MMM').format('MMMM YYYY')

        //Get project with the highest carbon footprint
        var highestFootprint = Math.max.apply(Math, tableData.projectEmissions.map(function(project) { return project.latestMonthTotal }))
        var projectWithHighestFootprint = tableData.projectEmissions.filter(project => project.latestMonthTotal === highestFootprint)[0].projectId

        //Calculate latest month total and trend
        var latestMonthTotal = tableData.projectEmissions.map(function(project) { return project.latestMonthTotal }).reduce((sum, total) => {return sum + total}, 0);
        var previousMonthTotal = tableData.projectEmissions.map(function(project) { return project.previousMonthTotal }).reduce((sum, total) => {return sum + total}, 0);
        var trend = (((latestMonthTotal - previousMonthTotal)/((previousMonthTotal)))*100).toFixed(2)

      } catch (error) {
        console.log(`'getSummaryData' failed to complete. Error Message: ${error.message}`)
        setAppErrors(`'getSummaryData' failed to complete. Error Message: ${error.message}`)
        setSustainabilityQueryStatus('error-fatal')
        
      }

      const summaryData = {
        "latestMonth": latestMonth,
        "latestMonthTotal": latestMonthTotal.toFixed(2),
        "trend": `${isNaN(trend) ? 0 : trend}%`,
        "projectWithHighestFootprint": projectWithHighestFootprint
      }

      setSummaryData(summaryData)
      
    }

    //Gets unattended projects table data from Firestore
    async function getUnattendedProjectsTableData(projects){

      const allProjectInsights = []

      //Loop through projects > Get Document  
      projects.forEach(project => {
                    
        //Check if project is unattended
        const results = GetDocument("unattendedprojects", project)
      
        //Append temp array
        allProjectInsights.push(results)

      });

      return Promise.allSettled(allProjectInsights)

    }

    //Returns the correct styling for upward vs. downward trend in carbon emissions
    function getTrendStyle(trend){

      //Check if negative to determine upward/downward trend
      if(trend?.length > 1 && trend?.charAt(0) === "-"){
        return (
          <span className='Monthly-Emissions-Trend-Down'>
            <img src={DownwardTrend} alt="trend-up" style={{marginRight: "5px"}}></img>
            {trend}
          </span>
        )
      } else if(trend !== "-" && trend !== "") {
        return(
          <span className='Monthly-Emissions-Trend-Up'>
            <img src={UpwardTrend} alt="trend-up" style={{marginRight: "5px"}}></img>
            {trend}
          </span>
        )
      } else if(trend === "") {
        return(
          <span>
            -
          </span>
        )
      } else {
        return(
          <span>
            {trend}
          </span>
        )
      }
    }

    //Returns the correct styling table and chart icon (filled if active/outline if inactive)
    function checkIconActive(icon, view){

      if(icon === view){
        return { backgroundColor: "#E8E9EB" }
      } else {
        return { backgroundColor:  "white" }
      }
      
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //======================================================
    //  Page Loads > Get the correct Project IDs
    //======================================================

    useEffect(()=>{

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Project view was selected
      if (getUser.preferences.globalSelector.selectedView === 'project') {
        
        setProjectIDs([getUser.preferences.globalSelector.selectedResource]);
        setPageStatus('onload');
        setSustainabilityQueryStatus('pending');

      }

      // Portfolio view was selected
      if (getUser.preferences.globalSelector.selectedView === 'portfolio') {

        GetDocument('portfolios', getUser.preferences.globalSelector.selectedResource)
        .then((results) => {
          setProjectIDs([...results['projects']]);
          setPageStatus('onload');
          setSustainabilityQueryStatus('pending');

        })
        .catch((error) =>{
          setAppErrors(`Unable to retrieve projects from the portfolio ${getUser.preferences.id}, error ${error}`);
          setPageStatus('error-fatal');

        });

      }

    // eslint-disable-next-line
    }, [getUser]);

    //======================================================
    //  Project IDs found > Get Table Data
    //======================================================

    useEffect(() => {

      //Check page is loading (all useStates have default values) > Call function to get Firestore data
      if (projectIDs.length === 0){
        setAppErrors('No projects found.')
        setSustainabilityQueryStatus('error-invalid');
      } 

      //----------------------------------------------------
      // Call 'getProjectEmissionsTableData' 
      //----------------------------------------------------

      const projectEmissionsPromise = getProjectEmissionsTableData(projectIDs);

      //----------------------------------------------------
      // Call 'getUnattendedProjectsTableData' 
      //----------------------------------------------------

      const unattendedProjectsPromise = getUnattendedProjectsTableData(projectIDs);

      //------------------------------------------------------
      //  Resolve both promises ('projectEmissionPromise', 'unattendedProjectsPromise')
      //------------------------------------------------------

      Promise.all([projectEmissionsPromise, unattendedProjectsPromise])
      .then((results) => {

        //Resolved promises
        const projectEmissionResults = results[0];
        const unattendedProjectResults = results[1];
        const arrayOfProjectEmissionPromises = projectEmissionResults.projectEmissions;

        //Check the status of each promise in the array of promises - 'projectEmissionsPromise.projectEmissions'
        const allProjectEmissions = [];
        const emissionsByProject = {};

        arrayOfProjectEmissionPromises.forEach((promise) => {

          if(promise.status === 'fulfilled' && promise?.value.length > 0){

            allProjectEmissions.push(promise?.value);

            //Save emissions data for the current month by project to use in the UPR table
            if (promise?.value[0].month_date === projectEmissionResults.latestMonthDisplayName){

              emissionsByProject[promise?.value[0].projectid] = promise?.value[0].total_emissions;

            }
          } 

        });

        //Check status of each promise in array of promises - 'unattendedProjectPromises'
        const allUnattendedProjects = [];
        var unattendedValue = {};
        unattendedProjectResults.forEach((promise) => {
          
          //Note* - Only checking fulfilled promises as documents that do not exist will return a rejected promise 
          if(promise.status === 'fulfilled' && promise?.value?.isunattended){

            unattendedValue = promise?.value;

            //Add emissions data to display on the UPR table
            if(unattendedValue.projectid in emissionsByProject){

              unattendedValue.carbonFootprintDailyKgCO2 = emissionsByProject[unattendedValue.projectid];

            } else {

              unattendedValue.carbonFootprintDailyKgCO2 = 0;

            }

            allUnattendedProjects.push(unattendedValue);
          } 

        });


        //------------------------------------------------------
        //  If project emissions exist > re-format data
        //------------------------------------------------------

        //If there are no projects with emissions > Show 'No emissions found' message.
        if(allProjectEmissions.length === 0) {
          
          setSustainabilityQueryStatus('error-invalid');
          return;
        
        }

        //Prepare array to store all project emissions
        const allProjectEmissionsData = [];
            
        allProjectEmissions.forEach(monthlyEmissions => {
                
          let projectSummary = {
              projectId: monthlyEmissions[0].projectid,
              latestMonthTotal: 0,
              previousMonthTotal: 0,
              trend: "",
              serviceEmissions: []
          }
      
          // ----------------------------------------------
          //  Loop through months  > Get emissions
          // ----------------------------------------------

          monthlyEmissions.forEach(month => {

              //No emissions > skip to next step
              if(!Object.keys(month.service_emissions).length > 0) return;

              // ----------------------------------------------
              //  Loop through services > Get emissions
              // ----------------------------------------------
              
              Object.entries(month.service_emissions).forEach(service => {

              //Check for existing service 
              const serviceIndex = projectSummary.serviceEmissions.findIndex(obj => obj.serviceName === service[0])
              const existingService = projectSummary['serviceEmissions'][serviceIndex]

              //Prepare service object -> If it exists then set to existing service object
              var serviceObject = {
                  "serviceName": service[0],
                  "locationEmissions": []
              }

              if(existingService !== undefined) {

                serviceObject = existingService;

              }

              // ----------------------------------------------
              //  Loop through locations > Get emissions
              // ----------------------------------------------

              Object.entries(service[1]).forEach(location => {

                  //Check for existing location
                  const locationIndex = serviceObject.locationEmissions.findIndex(obj => obj.locationName === location[0]);
                  const existingLocation = projectSummary['serviceEmissions'][serviceIndex]?.locationEmissions[locationIndex];

                  //Prepare location object -> If it exists then set to existing location 
                  var locationObject = {
                  "locationName": location[0],
                  "latestMonthTotal": 0,
                  "previousMonthTotal": 0
                  }

                  if(existingLocation !== undefined){

                    locationObject = existingLocation;

                  }

                  //If latest month > get latest month total, trend and service emissions
                  if(month.month_date === projectEmissionResults.latestMonthDisplayName){

                    projectSummary.latestMonthTotal = month.total_emissions;
                    projectSummary.trend = month.trend;
                    locationObject.latestMonthTotal = location[1];

                  }

                  //If previous month > get previous month total and service emissions
                  if(month.month_date === projectEmissionResults.previousMonthDisplayName){

                    projectSummary.previousMonthTotal = month.total_emissions;
                    locationObject.previousMonthTotal = location[1];

                  }
                  
                  //If the location exists > update object | Else > add object
                  if(existingLocation !== undefined){

                    serviceObject.locationEmissions[locationIndex] = locationObject;

                  } else {

                    serviceObject.locationEmissions.push(locationObject);
                  }

              })

              //If the service exists > update object | Else > add object
              if(existingService !== undefined){

                projectSummary.serviceEmissions[serviceIndex] = serviceObject;

              } else {

                projectSummary.serviceEmissions.push(serviceObject);
              
              }

            });           

          });

          //Push data to 'allProjectEmissionsData' before saving to table useState
          allProjectEmissionsData.push(projectSummary);

        });

        projectEmissionResults.projectEmissions = allProjectEmissionsData;

        //Set table data to newly formatted data
        setProjectEmissionsTableData({...projectEmissionResults});
        getSummaryData(projectEmissionResults);
        setSustainabilityQueryStatus('success');
                
        //------------------------------------------------------
        //  Loop through projects > Get unattended projects
        //------------------------------------------------------

        setUnattendedProjectsTableData([...allUnattendedProjects]);

      })
      .catch((error) =>{
        console.log(`Failed to resolve 'projectEmissionsPromise'/'unattendedProjectsPromise'. Error Message: ${error.message}`);
        setAppErrors(`Failed to resolve 'projectEmissionsPromise'/'unattendedProjectsPromise'. Error Message: ${error.message}`);
        setSustainabilityQueryStatus('error-fatal');

      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectIDs]);


  //======================================================
  //  Chart Icon Clicked > Get Chart Data
  //======================================================
    
    useEffect(() => {

      //Check page is loading (all useStates have default values) > Call function to get Firestore data
      if (projectIDs.length === 0){
        setAppErrors('No projects found.')
        setSustainabilityQueryStatus('error-invalid');
      } 

      //----------------------------------------------------
      // Call 'getProjectEmissionsChartData' 
      // This async function calls Firestore and retrieves emissions data for the last 12 months
      //----------------------------------------------------

      //Call Function > Get Chart Data
      const results = getProjectEmissionsChartData(projectIDs);
      results
      .then((response) => {

        //Inside the 'response' is an array of promises > We want to resolve them all and save to useState
        return Promise.all(response.projectEmissions)
        .then((projects) =>{
          
          //If there are no projects with emissions > Show 'No emissions found' message.
          if(projects.length === 0) {

            setSustainabilityQueryStatus('error-invalid');
            return;

          }     

          // ------------------------------------------------
          //  Prepare Line Chart Data Inputs
          // ------------------------------------------------

          const chartData = [];

          //Line Chart Headings > Add 'Month' title to beginning of array
          const arrayOfHeadings = ["Month", ...projectIDs];
          chartData.push(arrayOfHeadings);

          //Loop through array of months > projects > Get monthly totals for each project
          response.months.forEach(month => {

            const monthArray = [month];

            projects.forEach(monthlyEmissions => {
              
              //If there are emissions for this project for the month, extract them > Else set to 0 
              var monthTotal = 0;

              if(monthlyEmissions.length > 0){

                var monthTotalIndex = monthlyEmissions.findIndex(obj => obj.month_date === month);
                monthTotal = monthlyEmissions[monthTotalIndex]?.total_emissions ? monthlyEmissions[monthTotalIndex]?.total_emissions : 0;

              }

              monthArray.push(monthTotal);
            
            });

            chartData.push(monthArray);
          
          });

          //Set Chart Data
          setProjectEmissionsChartData([...chartData]);
          setSustainabilityQueryStatus('success');

        });

      })
      .catch((error) =>{
        console.log(`Failed to resolve promise(s) from 'getProjectEmissionsChartData' function. Error Message: ${error.message}`);
        setAppErrors(`Failed to resolve promise(s) from 'getProjectEmissionsChartData' function. Error Message: ${error.message}`);
        setSustainabilityQueryStatus('error-fatal');

      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectIDs]);


  //======================================================
  //  User clicks toggle > Show Unattended Projects
  //======================================================

    useEffect(() => {

      if(showUnattendedProjects) {
        setView("unattendedprojects");

      } else {
        setView("table");

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showUnattendedProjects]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        requireSelectedResource={true}
        requireSelectedViews={['project', 'portfolio']}
        requiredRoles={['projectOwner', 'projectOperator', 'portfolioOwner', 'portfolioViewer']}
        header={
          <PageHeader
            title={'Sustainability'}
            modaltitle={'Frequently Asked Questions'}
            modalbody={
              <div className="UPR-Modal">
                
                {/* Frequently Asked Questions */}
                <div className="UPR-FAQs">
                  <img src={CO2} alt="CO2Icon" style={{width: "1em", marginRight: "10px"}}></img>
                  <b>What are gross carbon emissions?</b>
                  <br></br>
                  Google looks at the gross carbon emissions produced by the computing infrastructure supporting its internal services. 
                  The footprints do not take into account Google's renewable power purchase agreements or other contracts signed to ensure we receive carbon-free electricity.
                  <br></br><br></br>

                  <img src={SustainabilityIcon} alt="Reduce Footprint Icon" style={{width: "1em", marginRight: "10px"}}></img>
                  <b>How can I reduce my Google Cloud carbon footprint?</b>
                  <br></br>
                  Refer to <a href="https://cloud.google.com/architecture/reduce-carbon-footprint#choose_the_most_suitable_cloud_regions" target="_blank" rel="noopener noreferrer">Reduce your Google Cloud carbon footprint</a>.

                  <br></br><br></br>

                  <img src={Lock} alt="UPR Lock Icon" style={{width: "1em", marginRight: "10px"}}></img>
                  <b>What is an unattended project?</b>
                  <br></br>
                  The unattended project recommender analyzes usage activity on projects in your organization and provides recommendations that help you discover or remove unattended projects.
                  A project is classified as <b>unattended</b> if it falls within 10% of usage activity in comparison to all other projects in a GCP organization.
                  <br></br>
                  Refer to <a href="https://cloud.google.com/recommender/docs/unattended-project-recommender#overview" target="_blank" rel="noopener noreferrer">Unattended Project Recommender</a>.
                  </div>

                {/* Related Links */}
                <div className="UPR-Links">
                  <b>Related Links</b>
                  <ul>
                    <li>
                      <a href="https://cloud.google.com/carbon-footprint/docs/methodology" target="_blank" rel="noopener noreferrer">Carbon Footprint Reporting Methodology</a>
                    </li>
                    <li>
                      <a href="https://googlecloudplatform.github.io/region-picker/" target="_blank" rel="noopener noreferrer">Google Cloud Region Picker</a>
                    </li>
                    <li>
                      <a href="https://www.lendlease.com/missionzero/" target="_blank" rel="noopener noreferrer">Lendlease Mission Zero</a>
                    </li>
                  </ul>
                </div>
              </div>
            }
            body={
              <div>
                <p>
                  View and track your gross cloud carbon emissions by project below.
                  For more information, refer to <a href="https://cloud.google.com/carbon-footprint" target="_blank" rel="noopener noreferrer">Carbon Footprint</a>.
                </p>
              </div>
            }
          ></PageHeader>
        }
        body={
          <SustainabilityResponse
            header={
            <>
              {/* Summary Pane */}
              <div className='Sustainability-Summary-Pane'>

                  {/* Latest Month Carbon Footprint */}
                  <div className='Metric-1'>
                      <b>{summaryData.latestMonth} Carbon Footprint</b>
                      <br></br>
                      <div className='Monthly-Emissions-Total'>
                        {summaryData.latestMonthTotal} kgCO2e 
                        {getTrendStyle(summaryData.trend)}
                      </div>
                  </div>
      
                  {/* Project with Highest Carbon Footprint */}
                  <div className='Metric-2'>
                      <b>Project with Highest Carbon Footprint</b>
                      <br></br>
                      {summaryData.projectWithHighestFootprint}
                  </div>
      
                  {/* Total Unattended Projects */}
                  <div className='Metric-3'>
                      <div className="UPR-Total-Label">
                        <b>Total Unattended Projects</b>
                        <div className="tooltip">
                          <img src={Tooltip} alt="Tooltip"></img>
                          <span className="tooltiptext"> Click "Show Unattended Projects" below to review your unattended projects. </span>
                        </div> 
                      </div>
                      {unattendedProjectsTableData.length}
                  </div>

              </div>
      
              {/* Filter Pane */}
              <div className='Sustainability-Filter-Pane'>
                
                  <h1 style={{flex: '40%'}}>
                    {showUnattendedProjects ? (<>Unattended Projects</>) : (<>Carbon Emissions by Project</>)}
                  </h1>
                  
                  {/* Unattended Projects */}
                  <b>Show Unattended Projects</b> 
                  <div onChange={() => setShowUnattendedProjects(!showUnattendedProjects)}>
                    <label className="switch">
                      <input type="checkbox" defaultChecked={showUnattendedProjects} ></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  
                  {
                    showUnattendedProjects ?
                    (
                      // If unattended projects toggle is active > Disable Chart/Table Icon
                      <>
                        <img style={checkIconActive("chart", view)} src={ChartDisabled} alt="Chart View"></img>
                        <img style={checkIconActive("table", view)} src={TableDisabled} alt="Table View"></img>
                      </>
                    )
                    :
                    (
                      // Else > Enable Chart/Table Icon
                      <>
                        <img style={checkIconActive("chart", view)} src={ChartViewOutline} alt="Chart View" onClick={() => setView("chart")}></img>
                        <img style={checkIconActive("table", view)} src={TableViewOutline} alt="Table View" onClick={() => setView("table")}></img>
                      </>
                    )
                  }

                  {/* Export to CSV button */}
                  <ExportToCSV
                    filename='emissions'
                    data={projectEmissionsChartData} 
                  ></ExportToCSV>

              </div>
            </>
            }
            tableView={
              <ProjectEmissionsTable
                emissionsData={projectEmissionsTableData}
                getTrendStyle={getTrendStyle}
              ></ProjectEmissionsTable>
            }
            chartView={
              <ProjectEmissionsChart
                emissionsData={projectEmissionsChartData}
              ></ProjectEmissionsChart>
            }
            unattendedProjects={
              <UnattendedProjectsTable
                unattendedProjectsData={unattendedProjectsTableData}
              ></UnattendedProjectsTable>
            }
            status={sustainabilityQueryStatus}
            view={view}
          ></SustainabilityResponse>
        }
      ></PageComponent>
    )
}
