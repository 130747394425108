//================================================================
//  Getting Help Page
//================================================================

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import SupportItemPanel from './Components/SupportItemPanel';

//Images
import Cuppa from '../../Components/Images/GetHelp_double_tbs.png'
import KnowledgeHub from '../../Components/Images/Icon_Book_Blue.svg'
import SupportTicket from '../../Components/Images/Icon_Person_Blue.svg'
import CloudGuild from '../../Components/Images/Icon_Cloud_Blue.svg'
import GoogleSupport from '../../Components/Images/Icon_Google_Logo.svg'
import ContactUs from '../../Components/Images/Icon_Contact_Us.svg'

//CSS
import './Support.css'


export default function Support() {

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------

    //Holds 'SupportPageItems' > Pre-filtering & Rendering
    //    - type: Name of the item categories
    //    - title: Name of the Catalogue item
    //    - body: Description
    //    - link: Path to the page defined in App.js
    //    - image: Path to the image, IE "../Components/Images/NAME.png"
    //    - label: Text of the label, example: "PREVIEW"
    //    - role: Array of roles of who can access this item, example: ["lendleaseAccount", "projectUser"]

    const SupportPageItems = [
      {
        "type": "item",
        "title": "Knowledge Hub",
        "body": "Access support documentation and FAQs.",
        "link": "/knowledgehub/query=all/category=getting-started",
        "image": KnowledgeHub,
        "label": "",
      },
      {
        "type": "item",
        "title": "Support Ticket",
        "body": "Log a support ticket via Service Now.",
        "linkType": "website",
        "link": "https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96",
        "image": SupportTicket,
        "label": "",
      },
      {
        "type": "item",
        "title": "Cloud Guild",
        "body": "Connect with the cloud community and get the latest updates, acccess to GCP training and events.",
        "linkType": "website",
        "link": "https://info.lendlease.cloud/theguild",
        "image": CloudGuild,
        "label": "",
      },
      {
        "type": "item",
        "title": "Google Support",
        "body": "Learn how to log a Google Support ticket.",
        "linkType": "website",
        "link": "https://storage.googleapis.com/lendleasecloud-cloudstudio-notification-publicresources/Google_Premium_Support_Filing_A_Support_Case.pdf", 
        "image": GoogleSupport,
        "label": "",
      },
      {
        "type": "item",
        "title": "Contact Us",
        "body": "Connect with one of our team members to give you a walk through of the portal features.",
        "link": "/contactus", 
        "image": ContactUs,
        "label": "",
      }

    ]

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <PageHeader
            title={'Get Help'}
            modaltitle={' '}
            modalbody={
              <div className="modal-lucky-image-gethelp">
                <img alt="Cuppa" src={Cuppa}></img>
                <div>Need help? Sometimes all you need is just double tea bags...</div>
              </div>
            }
            body={
              <div>
                Find the help you need by clicking a tile below.  
              </div>
            }
          ></PageHeader>
        }
        body={
        <div className="parent-container-gethelp">
          <div className="content-container-gethelp">
            
            {/* {useState array 'SupportItems' > Push into 'SupportItemPanel' Component} */}
            {SupportPageItems.map((item, index) =>
              (
                <div key={index}>
                  <SupportItemPanel 
                      key={item.title} 
                      type={item.type} 
                      title={item.title} 
                      link={item.link} 
                      body={item.body} 
                      image={item.image}
                      label={item.label}
                      // itemRole={item.role}
                      linkType={item.linkType}
                    >
                  </SupportItemPanel>
                </div>
              )
            )}

          </div>
        </div>
        }
      ></PageComponent>
    )
}
