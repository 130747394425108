//================================================================
//  Create/Delete DNS Zone Form
//================================================================

//Libraries
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Functions
import ReactBackend from '../../Library/reactBackend'

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';
import Disclaimer from '../../Components/Disclaimer/disclaimer';
import QuestionLabel from '../../Components/QuestionLabel'
import ErrorMessageLabel from '../../Components/ErrorMessageLabel'
import TextArea from '../../Components/TextArea/textarea'
import InlineInputField from '../../Components/InlineInputField/inlineinputfield'
import Label from '../../Components/Label/label'

//Images
import workingCatGraphic from '../../Components/Images/workingCatGraphic.svg'

//CSS
import './DNS.css';

export default function DNSRequest() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);
    var creatorGivenName = getUser?.profile?.givenname

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Retrieve paramaters from getUser global context
  //------------------------------------------------------
  
    let fields = getUser?.preferences?.dns?.fields
    let requestType = getUser?.preferences?.dns?.action

    // Need to fill these in even if missing, otherwise will generate errors
    // prior to the "onload" useEffect returning back to the DNS Zones page
    // If missing, "onload" useEffect will force back to the DNS Zones page
    if (fields === undefined || requestType === undefined) {
      requestType = 'missing'
      fields = {
        "dnsName": {
          "visibility": "read",
          "value": ""
        },
        "description": {
          "visibility": "read",
          "value": ""
        },
        "serviceAccount": {
          "visibility": "read",
          "value": ""
        }
      }
    }

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'onload' 'complete' 'pending', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');
    const [pageTitle, setPageTitle] = useState();

    // If select org changes, go back to DNS page
    const [currentOrgId, setCurrentOrgId] = useState();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Holds the inputs of the Form
    const [selectedValue, setSelectedValue] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        dnsName: fields.dnsName.value,
        description: fields.description.value,
        serviceAccount: fields.serviceAccount.value
      }
    );

    //Holds the styling for all fields > Used for error messaging
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        dnsNameErrorVisible: false,
        dnsNameErrorMessage: "Please enter a DNS name between 1-63 characters. Lowercase letters, numbers, hyphens allowed.",
        descriptionErrorVisible: false,
        descriptionErrorMessage: "Please enter a description.",
        serviceAccountErrorVisible: false,
        serviceAccountErrorMessage: "Please enter your service account ID.",
      }
    );

  //------------------------------------------------------
  //  Prepare request body parameters
  //------------------------------------------------------
 
    //Prepare the dns name
    var dnsName = selectedValue.dnsName.trim().toLowerCase().split(" ").join("")

    //Prepare dns label
    let dnsInlineLabel = '.' + process.env.REACT_APP_ORG_NAME
    let dnsNameLabel = dnsName + dnsInlineLabel;

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to change the 'PageDetailHeader' content 
    function DefinePageDetailHeader(){

      if (requestType === 'delete'){
        return (
          <div>
            <p>
              Deleting DNS zone {dnsNameLabel}
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p>
              <b style={{color: "#212c61"}}>Prerequisites</b>
              
              <ul>
                <li>You have a provisioned a service account, that you will use to create/manage your DNS zone record sets, e.g. <i>dns-robot@dnstesting57054.iam.gserviceaccount.com</i>. No additional permissions need to be provided to this account.</li>
              </ul>

              For more information, refer to <DocumentLink docId="973930558" title="DNS Services - How to Get Started"></DocumentLink>.
            </p>
          </div>   
        );
      }
    }

  //------------------------------------------------------
  //  Define Functions > Field Validation (Regex)
  //  * - Paste Regex in 'https://regexr.com/' for explanation
  //------------------------------------------------------
  
    //Function to validate DNS Name > 1-63 characters, lowercase alphanumeric characters including hyphens
    function isValidDNSName(DNSName) {  
      if (/^(?:[a-z0-9](?:[-a-z0-9]{0,63}[-a-z0-9])?)$/g.test(DNSName)) {  
        return (true)  
      }  
      return (false)  
    }  

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------

    //Used to call "submitDNSZoneRequest"
    const callAPI = async () => {

      var requestBody = {
        "requestType": requestType,
        "dnsName": selectedValue.dnsName,
        "description": selectedValue.description,
        "serviceAccountEmail": selectedValue.serviceAccount,
        "creatorGivenName": creatorGivenName
      }

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('submitDNSZoneRequest', requestBody)

      //Switch on formStatus
      if(response?.status === 'success'){
            
        //Set formStatus to 'success'
        setPageStatus('success')

      } else if(response?.status === 'error-invalid'){

        //Set formStatus to 'error-invalid'
        setAppErrors(response?.responseBody?.message)
        setPageStatus('error-invalid')

      } else {
          
        //Set formStatus to 'error-fatal'
        setPageStatus('error-fatal')
      }
      
      return response
    }

  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------

    //Used to get values from the form
    function handleChange(evt){
        
      const name = evt.target.name;
      const newValue = evt.target.value;

      //Validate field - dnsName
      if(name === 'dnsName'){
        //Field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){
          setErrorState({ dnsNameErrorVisible: true });
          setErrorState({ dnsNameErrorMessage: "Please enter a DNS name."});
          
        //Contains a dot > Set 'errorVisible' to true
        } else if(newValue.includes(".")){
          setErrorState({ dnsNameErrorVisible: true });
          setErrorState({ dnsNameErrorMessage: "DNS name suffix of zone cannot contain a dot."});
        
        //Exceeds character limit > Set 'errorVisible' to true
        } else if(newValue.length > 63){
          setErrorState({ dnsNameErrorVisible: true });
          setErrorState({ dnsNameErrorMessage: newValue.length + "/63 characters used. DNS name exceeded character limit."});
        
        //Invalid characters > Set 'errorVisible' to true
        } else if(!isValidDNSName(newValue)){
          setErrorState({ dnsNameErrorVisible: true });
          setErrorState({ dnsNameErrorMessage: "Please enter a DNS name between 1-63 characters. Lowercase letters, numbers, hyphens allowed."});
        
        //Default border and error visibility
        } else {            
          setErrorState({ dnsNameErrorVisible: false });
        }
      }

      //Validate field - description
      if(name === 'description'){
        //If field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){
          setErrorState({ descriptionErrorVisible: true });
        } else{
          setErrorState({ descriptionErrorVisible: false });
        }
      }

      //Validate field - service account email
      if(name === 'serviceAccount'){
        //If field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){
          setErrorState({ serviceAccountErrorVisible: true });
          setErrorState({ serviceAccountErrorMessage: "Please enter your service account ID."});
        //Default border and error visibility
        } else {            
          setErrorState({ serviceAccountErrorVisible: false });
        }
      }

      setSelectedValue({ [name]: newValue });
    };


  //------------------------------------------------------
  //  Event Handler > Form Submission
  //------------------------------------------------------

    //Used to call handle 'onsubmit' event and callAPI() function
    function eventHandlerOnSubmit(){  

      //Validate fields
      if(!isValidDNSName(selectedValue.dnsName)){

        //DNS field empty || > 63 characters > Set 'errorVisible' to true
        setErrorState({ dnsNameErrorVisible: true });
    

      } else if(selectedValue.description.length <= 0){

        //Description field empty > Set 'errorVisible' to true
        setErrorState({ descriptionErrorVisible: true });

      } else if(selectedValue.serviceAccount.length <= 0){

        //Description field empty > Set 'errorVisible' to true
        setErrorState({ serviceAccountErrorVisible: true });

      } else {

        //Set formStatus to 'pending'
        setPageStatus('pending')
        
        //Call the API
        callAPI()

      }
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // On load, get org and request information
    useEffect(() => {

      // Retrieve org ID from user context
      const orgId = getUser.preferences?.globalSelector?.selectedResource

      if(orgId === undefined) {
        navigate('/dns')
        return;
      }

      // Set page title based on request
      if(requestType === 'create') {
        setPageTitle('Create a DNS Zone')

      } else if (requestType === 'delete') {
        setPageTitle('Delete a DNS Zone')

      } else {
        navigate('/dns')
        return;        
      }

      // If onload, show disclaimer and save current org ID
      if(currentOrgId === undefined) {
        setPageStatus('disclaimer')
        setCurrentOrgId(orgId)
        return;
      }
      
      return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // If page status is set to pending and it's a delete action
    // Means the disclaimer was accepted, so call the API
    useEffect(() => {
      if(pageStatus === 'pending' && requestType === 'delete') {
        callAPI()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageStatus]);

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return(
      <PageComponent
        requireSelectedViews={['organization']}
        requireSelectedResource={true}
        requiredRoles={['lendleaseAccount']}
        requiredRolesValue={'true'}
        status={pageStatus}
        breadcrumb={{
          name: "DNS Management",
          route: "/dns"
        }}
        disclaimerContent={[
          <Disclaimer
            cancelRoute="/dns"
            setStatus={setPageStatus}
            setStatusValue={requestType === 'delete' ? "pending" : "onload"}
            acceptanceCriteria={[
              <ul>
                <li>I am authorized to perform this action</li>
              </ul>
            ]}
          ></Disclaimer>
        ]}
        header={
          <PageHeader
            title={pageTitle}
            body={DefinePageDetailHeader()}
          ></PageHeader>
        }
        body={
          <div className="Form-Container">
            <div className="Form-Body">

              {/*------------------------------------------------------------------ */}
              {/*   Q1 - DNS Name *                                                 */}
              {/*------------------------------------------------------------------ */}


              <QuestionLabel question={"DNS Name *"} helpText = {"The DNS name suffix of the zone."}></QuestionLabel>

                <InlineInputField
                  //Inline Input Field Properties
                  name={"dnsName"}
                  placeholder={"e.g. myzone"}
                  inlinefieldlabel= {dnsInlineLabel}
                  value={selectedValue.dnsName}
                  position={"right"}
                  errorVisible={errorState.dnsNameErrorVisible}
                  inputFieldLength={"mediuminputfield"}
                  visibility={fields.dnsName.visibility}

                  //Function Properties
                  onChange={handleChange}
                >
                </InlineInputField>

              <ErrorMessageLabel errorMessage={errorState.dnsNameErrorMessage} errorVisible={errorState.dnsNameErrorVisible}></ErrorMessageLabel>


              {/*------------------------------------------------------------------ */}
              {/*   DNS Name Label                                                  */}
              {/*------------------------------------------------------------------ */}


              <Label label={dnsNameLabel} type="tag"></Label>


              {/*------------------------------------------------------------------ */}
              {/*   Q2 - Description *                                              */}
              {/*------------------------------------------------------------------ */}


              <QuestionLabel question={"Description *"} helpText = {"Please enter a description of your DNS zone, for your reference."}></QuestionLabel>

                <TextArea
                  //TextArea Properties
                  name={"description"}
                  placeholder={"Enter a description"}
                  value={selectedValue.description}
                  errorVisible={errorState.descriptionErrorVisible}
                  visibility={fields.description.visibility}

                  //Function Properties
                  onChange={handleChange}
                >
                </TextArea>

              <ErrorMessageLabel errorMessage={errorState.descriptionErrorMessage} errorVisible={errorState.descriptionErrorVisible}></ErrorMessageLabel>


              {/*------------------------------------------------------------------ */}
              {/*   Q3 - Service Account Email *                                    */}
              {/*------------------------------------------------------------------ */}


              <QuestionLabel question={"Service Account Email Address*"} helpText = {"This service account will be used to create/manage your DNS zone record sets. For example, service-account@project123.iam.gserviceaccount.com"}></QuestionLabel>

                <InlineInputField
                  //Inline Input Field Properties
                  name={"serviceAccount"}
                  placeholder={"service-account@project123.iam.gserviceaccount.com"}
                  value={selectedValue.serviceAccount}
                  errorVisible={errorState.serviceAccountErrorVisible}
                  visibility={fields.serviceAccount.visibility}
                  inputFieldLength={"longinputfield"}
                  position={"left"}

                  //Function Properties
                  onChange={handleChange}
                >
                </InlineInputField>

              <ErrorMessageLabel errorMessage={errorState.serviceAccountErrorMessage} errorVisible={errorState.serviceAccountErrorVisible}></ErrorMessageLabel>


              {/*------------------------------------------------------------------ */}
              {/*   Submit Button                                                   */}
              {/*------------------------------------------------------------------ */}

              <div className='Button-Group'>
                <button className='Primary-Button' type='submit' onClick={(e)=>eventHandlerOnSubmit(e)}>Submit</button>
                <button className='Secondary-Button' onClick={() => navigate('/dns')}>Cancel</button>
              </div>

              {/*------------------------------------------------------------------ */}

            </div>
          </div>
        }
        successContent={
          <div className='PageComponent-Errors-Container'>
            <img style={{margin: "20px 0px", width: "40%"}} src={workingCatGraphic} alt="working cat"/>
            <h2>Thanks for your request. We're working on it!</h2>
            <p>
              You have successfully submitted a DNS zone request.
            </p>
            {
              requestType === 'create' ? (
                <p>
                  Once you have received your confirmation email, follow the steps under <b>Create a DNS Zone</b> in our <u><DocumentLink docId="973930558" title="DNS Services - How to Get Started"></DocumentLink></u> guide.
                </p>
              ) : (null)
            }
          </div>
        }
      ></PageComponent>
    )
}