//==========================================================================
//   Component: Project Emissions Table
//==========================================================================

//   Purpose: Table displaying all project level emissions for a user

//   Properties: 
//   - emissionsData = {data used to populate the rows in the table}
//   - getTrendStyle = {Function to style the trend depending on if the trend is positive/negative}

//   Example: 
//   <ProjectEmissionsTable
//       emissionsData={projectEmissionsTableData}
//       getTrendStyle={getTrendStyle}
//   ></ProjectEmissionsTable>

//------------------------------------------------------

//Libraries
import React from 'react'

//Components
import ProjectEmissionsTableRow from './projectemissionstablerow'

//CSS
import './sustainabilitycomponents.css'

export default function ProjectEmissionsTable({
    emissionsData,
    getTrendStyle
}) {

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

    return (
        <div className="Sustainability-Response-Success">
            { 
                emissionsData.projectEmissions.length > 0 ? 
            
                //If emissions data exists > display table
                (
                    <table className="Project-Emissions-Table">
                        <colgroup>
                            <col span="1" style={{width: "22.5%"}}></col>
                            <col span="1" style={{width: "22.5%"}}></col>
                            <col span="1" style={{width: "22.5%"}}></col>
                            <col span="1" style={{width: "22.5%"}}></col>
                            <col span="1" style={{width: "10%"}}></col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>Project</th>
                                <th>Carbon Emissions (kgCO2e)</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr style={{fontWeight: "bold", backgroundColor: "rgb(250 250 250)"}}>
                                <td></td>
                                <td>{emissionsData.previousMonthDisplayName}</td>
                                <td>{emissionsData.latestMonthDisplayName}</td>
                                <td>Trend</td>
                                <td></td>
                            </tr>
                            {
                                emissionsData.projectEmissions.map((row, index) => (
                                    <ProjectEmissionsTableRow
                                        index={index}
                                        rowData={row}
                                        getTrendStyle={getTrendStyle}
                                    ></ProjectEmissionsTableRow>
                            ))
                            }
                        </tbody>
                    </table>
                ) : 

                //If no emissions data > display message
                (
                    <div style={{padding: "20px"}}>
                        No emissions found.
                    </div>
                )
            }
        </div>
    )
}
