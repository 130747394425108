//================================================================
//  Change How You Manage Firewall Rules Page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Functions
import ReactBackend from '../../Library/reactBackend';
import GetDocument from '../../Library/GetDocument';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';
import QuestionLabel from '../../Components/QuestionLabel';
import InputField from '../../Components/InputField/inputfield';
import ErrorMessageLabel from '../../Components/ErrorMessageLabel';

//Images
import PrideCat from '../../Components/Images/PrideCat.png';

//CSS
import './Firewall.css';


export default function FirewallManagement() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to save page status > 'pending', 'success', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
    const [pageStatus, setPageStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Used to change between > 'portal', 'iac'
    const [managementRequestType, setManagementRequestType] = useState('');


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Holds the inputs of the Form
    const [selectedValue, setSelectedValue] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        serviceAccount: "",
        projectId: ""
      }
    );

    //Holds the styling for all fields > Used for error messaging
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        serviceAccountErrorVisible: false,
        serviceAccountErrorMessage: "Please enter a valid service account, e.g. exampleaccount@myproject.iam.gserviceaccount.com",
        projectIdErrorVisible: false, 
        projectIdErrorMessage: "Please enter a valid project ID."
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to call "submitChangeFirewallManagementTypeRequest"
    const callAPI = async (managementRequestType) => {

      //Prepare service account address
      const serviceAccountAddress = selectedValue.serviceAccount + "@" + selectedValue.projectId.toLowerCase() + ".iam.gserviceaccount.com"

      var requestBody = {
        "projectId": getUser?.preferences?.globalSelector?.selectedResource,
        "firewallManagementType": managementRequestType,
        "firewallUserManagedSa": serviceAccountAddress
      }

      //Set formStatus to 'pending'
      setPageStatus('pending')

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('submitChangeFirewallManagementTypeRequest', requestBody)

      //Switch on API response status
      if(response?.status === 'success'){
            
        //Set formStatus to 'success'
        setPageStatus('success')

      } else if(response?.status === 'error-invalid'){

        //Set formStatus to 'error-invalid'
        setAppErrors(response?.responseBody?.message)
        setPageStatus('error-invalid')

      } else {
          
        //Set formStatus to 'error-fatal'
        setPageStatus('error-fatal')

      }
    }   

    //Function to validate the service account ID
    //Paste Regex in 'https://regexr.com/' for explanation
    function isValidServiceAccount(ServiceAccount){  
      if (/^(?:[a-z0-9](?:[-a-z0-9]{0,30}[-a-z0-9])?)$/g.test(ServiceAccount)) {  
        return (true)  
      }  
      return (false)  
    } 

    //Function to validate the 'projectName'
    function IsValidProjectName(projectName) {  
      if (/^(?:[a-z](?:[-a-z0-9]{0,61}[a-z0-9])?)$/g.test(projectName)) {  
        return true;
      }
      return false;
    }  

  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------

    //Event Handler for field changes 
    const handleChange = evt => {
      
      const name = evt.target.name;
      const newValue = evt.target.value;

      //Validate service account 
      if(name === 'serviceAccount'){

        //Validate service account 
        //Field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){
          setErrorState({ serviceAccountErrorVisible: true });
          setErrorState({ serviceAccountErrorMessage: "Please enter a service account name."});
        
        //Exceeds character limit > Set 'errorVisible' to true 
        } else if(newValue.length > 30){
          setErrorState({ serviceAccountErrorVisible: true });
          setErrorState({ serviceAccountErrorMessage: newValue.length + "/30 characters used. The service account name has exceeded the character limit."});
          
        //Invalid characters > Set 'errorVisible' to true
        } else if(!isValidServiceAccount(newValue)){
          setErrorState({ serviceAccountErrorVisible: true });
          setErrorState({ serviceAccountErrorMessage: "Invalid service account name. Service account name must be between 6-30 characters. Lowercase letters, numbers and hyphens are allowed."});
        
        //Default border and error visibility
        } else {
          setErrorState({ serviceAccountErrorVisible: false });
        }
      }

      //Validate project ID 
      if(name === 'projectId'){

        //Field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){
          setErrorState({ projectIdErrorVisible: true });
          setErrorState({ projectIdErrorMessage: "Please enter a valid project ID."});;

        //Invalid project ID > Set 'errorVisible' to true
        } else if(!IsValidProjectName(newValue)){
          setErrorState({ projectIdErrorVisible: true });
          setErrorState({ projectIdErrorMessage: "Invalid project ID. Project ID must be between 0-61 characters. Lowercase letters, numbers and hyphens are allowed."});
        
        //Else > No errors
        } else{
          setErrorState({ projectIdErrorVisible: false });
        }

      }

      //Set selected value to field value
      setSelectedValue({ [name]: newValue });


    };

  //------------------------------------------------------
  //  Event Handler > Form Submission
  //------------------------------------------------------

    //Event handler when form is submitted > validate all fields
    const eventHandlerOnSubmit = e => {  

      //This prevents the form from been submitted
      var preventSubmit = false;

      //Service account field empty > Set 'errorVisible' to true
      if(selectedValue.serviceAccount.length <= 0){
        setErrorState({ serviceAccountErrorVisible: true });
        setErrorState({ serviceAccountErrorMessage: "Please enter a service account name."})
        preventSubmit = true;
      } 

      //Invalid service account name > Set 'errorVisible' to true
      else if(!isValidServiceAccount(selectedValue.serviceAccount)){
        setErrorState({ serviceAccountErrorVisible: true });
        setErrorState({ serviceAccountErrorMessage: "Invalid service account name. Service account name must be between 6-30 characters. Lowercase letters, numbers and hyphens are allowed."});
        preventSubmit = true;
      }

      //Project ID is empty > Set 'errorVisible' to true
      if(selectedValue.projectId.length <= 0){
        setErrorState({ projectIdErrorVisible: true });
        setErrorState({ projectIdErrorMessage: "Please enter a project ID."});
        preventSubmit = true;
      }

      //Invalid project name > Set 'errorVisible' to true
      else if(!IsValidProjectName(selectedValue.projectId)){
        setErrorState({ projectIdErrorVisible: true });
        setErrorState({ projectIdErrorMessage: "Invalid project ID. Project ID must be between 0-61 characters. Lowercase letters, numbers and hyphens are allowed."});
        preventSubmit = true;
      }

      //Else if errorVisible > preventSubmit
      if (errorState.serviceAccountErrorVisible === true || errorState.projectIdErrorVisible === true){
        preventSubmit = true;
      }

      //Allow the form to be submitted
      if (preventSubmit === false){
        setPageStatus('pending')
        e.preventDefault();
        callAPI('user-managed')
      }

      //Prevent reload
      e.preventDefault();
  
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
  
    // Onload > retrieve current firewall management type
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible === true) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      GetDocument('projects', getUser.preferences.globalSelector.selectedResource)
      .then((results) =>{
        
        setManagementRequestType(results.firewallmanagementtype);
        setPageStatus('onload');

      })
      .catch((error) => {

        setAppErrors(`Could not retrieve management type from project document '${getUser?.preferences?.globalSelector?.selectedResource}' in Firestore. Error: ${error.message}`)
        setPageStatus('error-invalid');

      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedViews={['project']}
        requireSelectedResource={true}
        requiredRoles={['projectOwner', 'projectOperator']}
        requiredEnvironments={['Standard']}
        status={pageStatus}
        breadcrumb={{
          name: "Firewall",
          route: "/firewall"
        }}
        header={
          <PageHeader
            title={'Change How You Manage Firewall Rules'}
            body={
              <>
                Lendlease Cloud supports management of firewall rules through IaC or the portal and can be changed at any time. 
                <br></br>
                For more information, refer to <DocumentLink docId="1119551520" title="Firewall Services - How to Get Started"></DocumentLink>.
              </>   
            }
          ></PageHeader>
        }
        body={
          <>
            
            {
              managementRequestType === 'portal' ? 
              (
              // ==================================================
              //   'user-managed' Form                           
              // ==================================================

                <>
                  <div className="Firewall-Management-Pane">
                    <div>
                      The firewall rules in this project <b>{getUser.preferences.globalSelector.selectedResource}</b> are currently managed using the Lendlease Cloud Portal.
                    </div>
                  </div>

                  <form className="Form-Body" id="firewallmanagementtypeform" onSubmit={eventHandlerOnSubmit}>
                    
                    <h3>Manage Your Firewall Rules by IaC</h3>

                    To manage your firewall rules using IaC (infrastructure as code), fill in the form below.

                    <ul style={{lineHeight: "1.6"}}>
                      <li>Firewall rules will be managed by an API.</li>
                      <li>To retain your existing firewall rules, ensure that they are included in your IaC firewall rule definition file.</li>
                      <li>You have a deployment account (Google service account) which will be used to deploy firewall rules.</li>
                    </ul>

                    {/* Service Account Field */}
                    <QuestionLabel question={"Please provide a service account below"} helpText = {"Any previous service account will be overwritten by the account provided below."}></QuestionLabel>

                    <div>
                      <InputField
                        //Input Field Properties
                        name={"serviceAccount"}
                        placeholder={"service account name"}
                        value={selectedValue.serviceAccount}
                        className="shortinputfield"
                        errorVisible={errorState.serviceAccountErrorVisible}
                            
                        //Function Properties
                        onChange={handleChange}
                      >
                      </InputField>

                      @

                      <InputField
                        //Input Field Properties
                        name={"projectId"}
                        placeholder={"project id"}
                        value={selectedValue?.projectId}
                        className="shortinputfield"
                        errorVisible={errorState?.projectIdErrorVisible}
                            
                        //Function Properties
                        onChange={handleChange}
                      >
                      </InputField>

                      .iam.gserviceaccount.com

                    </div>

                    {/* Error Messages */}
                    <ErrorMessageLabel errorMessage={errorState.serviceAccountErrorMessage} errorVisible={errorState.serviceAccountErrorVisible}></ErrorMessageLabel>
                    <ErrorMessageLabel errorMessage={errorState.projectIdErrorMessage} errorVisible={errorState.projectIdErrorVisible}></ErrorMessageLabel>


                    {/* Submit Button*/}
                    <div className='Button-Group'>
                      <input className='Primary-Button' type="submit" value="Submit"></input>
                      <button className='Secondary-Button' onClick={() => navigate('/firewall')}>Cancel</button>
                    </div>

                  </form>
                </>

              ) 
              : 
              (
              // ================================================== 
              // 'portal' managed Form                            
              // ==================================================

              <>
              
                <div className="Firewall-Management-Pane">
                  <div>
                    The firewall rules in this project <b>{getUser.preferences.globalSelector.selectedResource}</b> are currently managed by IaC (infrastructure as code).
                  </div>
                </div>

                <div className="Form-Body">

                 <h3>Manage Your Firewall Rules by the Portal</h3>

                  To manage your firewall rules using the Lendlease Cloud Portal, click submit below.

                  <ul>
                    <li>Firewall rules will ONLY be managed in the portal.</li>
                  </ul>
                
                  <div className='Button-Group' style={{margin: "20px 0px 5px 0px"}}>
                    <button className='Primary-Button' onClick={() => callAPI('portal')}> Submit </button>
                    <button className='Secondary-Button' onClick={() => navigate('/firewall')}> Cancel </button>
                  </div>

                </div>

              </>

              )
            }

          </>
        }
        successContent={
          <div className='PageComponent-Errors-Container'>
            <img style={{width: "35%"}} src={PrideCat} alt="happy cat"/>
            <h2>Success!</h2>
              <p>
                Your request to change how you manage your firewall rules is complete.
              </p>
          </div>
        }
      ></PageComponent>
    )
}
