//------------------------------------------------------
//  Google BarCharts
//  Created by Mark Bennett 14/09/2021
//------------------------------------------------------  

//Libaries
import React from 'react'
import Chart from "react-google-charts";


export default function BarChart(props) {

  //------------------------------------------------------
  //  How to use the 'BarChart' component
  //  https://react-google-charts-docs.netlify.app/examples/bar-chart/
  //------------------------------------------------------  
  //    
  //    1. Import this component
  //    2. Use the below inside a form component
  //    3. Note: Check out the above page for different options

  //    <BarChart
  //     width={'350px'}
  //     key={chartWidthKey}
  //     height={'300px'}
  //     data={[
  //     ['Current vs Required', 'Spend'],
  //     ['Current', rateOfSpend],
  //     ['Required', requiredRateOfSpend],
  //     ]}
  //     options={{
  //     legend: { position: 'none' },
  //     }}
  //     >
  //    </BarChart>


  //------------------------------------------------------
  // Props
  //------------------------------------------------------  

    var width = props.width
    var height = props.height
    var key = props.key
    var data = props.data
    var options = props.options

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
        <Chart
          width={width}
          height={height}
          key={key}
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={data}
          options={options}
        >
        </Chart>
    )

  //------------------------------------------------------
}
