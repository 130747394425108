//================================================================
//  Modify Project Ownership Lookup Form
//================================================================

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Functions

//Components
import GroupManagement from '../../Components/GroupManagement/GroupManagement';
import PageHeader from '../../Components/PageHeader/PageHeader';

//CSS
import './modifygroups.css';


function ModifyProjectOwnerLookup(){

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
      
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
  
    return (
      <GroupManagement
        groupId={getUser?.preferences?.projectownerlookup?.id}
        requestType='secops'
        header={
          <PageHeader
            title={"Edit 'Project Owner Lookup' Page Access"}
            body={
              <p>
                Please enter the user(s) you would like to assign to view the 'Project Owner Lookup' page and click 'Submit' below.
                <br></br>
                <b>Note</b> - All users must have a Cloud Passport account. If they do not have an account, you will be prompted to invite them when you click 'Submit'.
              </p>   
            }
          ></PageHeader>
        }
        breadcrumb={{
          "name": "Project Owner Lookup", 
          "route": "/projectownerlookup/view"
        }}
        requireSelectedResource={true}
        requireSelectedViews={['organization']}
        requiredRoles={['portalSecOps', 'portalAdmin']}
        requiredRolesValue={'true'}
      ></GroupManagement>
    );


  //------------------------------------------------------
}

export default ModifyProjectOwnerLookup;