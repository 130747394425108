//================================================================
//  Billing Page
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import {GetUser} from '../../Library/GlobalContexts';

//Functions
import ReactBackend from '../../Library/reactBackend';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import BillingTableRow from './Components/BillingTableRow';
import SelectBox from '../../Components/SelectBox/selectbox';

//Images
import InfoIcon from '../../Components/Images/Icon_Info_Black.svg';
import LoadingIcon from '../../Components/Images/Loading_Ripple.svg';

//CSS
import './Billing.css';


export default function Billing() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to change between > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal', 'error-timeout'
    const [pageStatus, setPageStatus] = useState('pending');

    // Holds the current projectId, used to prevent unnecessary reloads of the page
    const [projectIds, setProjectIds] = useState();

    // Holds all the invoices
    const [invoices, setInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);

    // Filters
    const [financialYear, setFinancialYear] = useState('All');

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload get all invoices for all projects
    useEffect(()=>{

      // Conditions
      if (getUser === undefined) return;
      if (getUser.profile?.views === undefined) return;
      if (getUser.profile.views.projectsOwner?.length === 0) return  setPageStatus('error-other');
      if (getUser?.profile?.views?.projectsOwner?.length === projectIds?.length) return;
      
      // Save the projectIds & Tell users this might take some time!
      setProjectIds(getUser.profile.views.projectsOwner);
      setPageStatus('onload');

      // Loop through projects > request invoices
      const arrayOfPromises = [];
      getUser.profile.views.projectsOwner?.forEach(projectid => {

        //Set request body
        const requestBody = {
          "projectId": projectid,
          "productType": "primaryOrg"
        }
        arrayOfPromises.push(
          ReactBackend('getAllInvoices', requestBody)
        );

      });

      // Handle 'arrayOfPromises'
      Promise.allSettled(arrayOfPromises).then((responses)=> {

        const arrayOfInvoices = [];
        responses.forEach((response) =>{

          if (response.status === 'rejected') return;

          if (response.value.status === 'success'){
            
            arrayOfInvoices.push(response.value.responseBody.message);
  
          }
  
        });

        // We have invoices, show the user
        setFilteredInvoices(arrayOfInvoices);
        setInvoices(arrayOfInvoices);
        setPageStatus('success');
      
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // Financial year filter
    useEffect(()=>{

      //Clear filters
      if (invoices === undefined) return;
      if (invoices.length === 0) return;
      if (financialYear === 'All') return setFilteredInvoices(invoices);

      //Apply filter
      const filteredArray = [];
      invoices.forEach((project) =>{

        // Skip any projects with no invoices
        if(project?.invoices?.length > 0){

          const results = project?.invoices.filter((invoice) => (invoice.financialYear === financialYear));

          // SKip projects with no results
          if(results.length === 0) return;

          // Save projects with invoices
          filteredArray.push({
            'projectId': project.projectId,
            'invoices': results
          });

        }

      });

      setFilteredInvoices(filteredArray);

    }, [financialYear, invoices]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['organization']}
      requiredRoles={['lendleaseAccount']}
      requiredRolesValue={'true'}
      status={pageStatus}
      header={
        <PageHeader
          title={'Billing'}
          body={
            <span>
              Browse invoices for lendlease.cloud Google projects.
            </span>
          }
        ></PageHeader>
      }
      // Loading screen, as retriving invoices can take some time!
      body={
        <>
          <div className='PageComponent-Label'>
            <div className='PageComponent-LabelMessage'>
              <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
              <p>Searching {getUser?.profile?.views?.projectsOwner?.length} projects for invoices, this will take a moment...</p>
            </div>
          </div>

          <div style={{textAlign: 'center'}}>
            <img alt="loading-circle-icon" src={LoadingIcon}></img>
          </div>
        </>
      }
      // Retrieved invoices, load the page
      successContent={
        <>

          {
            /* Coin Financial Years (only show if there are invoices) */
            invoices > 0 ? (
              <div className='Billing-Filter-Pane'>
                <div>
                  <span>Financial Year:</span>
                  <SelectBox
                    placeholder='All'
                    value={financialYear}
                    arrayOfOptions={['All', 'FY21', 'FY22', 'FY23']}
                    onChange={(e) => setFinancialYear(e.target.value)}
                  ></SelectBox>
                </div>
              </div>
            ) : (null)
          }

          {/* Invoice table */}
          <div className='Billing-Invoices-Pane'>
            {
              filteredInvoices.length > 0 ? (

                filteredInvoices?.map((project) =>(
                  <table className='Data-Table-Container'>
                    <tbody>
                      <BillingTableRow
                        rowData={project}
                      ></BillingTableRow>
                    </tbody>
                  </table>
                ))
              ): 
              
              // No invoices found
              (
                <div className='PageComponent-Label'>
                  <div className='PageComponent-LabelMessage'>
                    <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                    <p>No invoices found.</p>
                  </div>
                </div>
              )
            }
          </div>

        </>
      }

      // User doesn't own any projects
      errorOtherContent={
        <div className='PageComponent-Label'>
          <div className='PageComponent-LabelMessage'>
            <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
            <p>Sorry you don't own any projects.</p>
          </div>
        </div>
      }
    ></PageComponent>
  )
}
