//==========================================================================
//   Component: Billing Table Row
//==========================================================================

//   Purpose: Shows an expandable row in the Active Firewall Rules table

//   Properties: 
//   - rowData = {data used to populate row in the table}

//   Example:
//   <BillingTableRow
//     rowData={row}
//   ></BillingTableRow>

//------------------------------------------------------


//Libraries
import React, { useState } from 'react';

//Components
import ModalContent from '../../../Components/ModalContent/modalcontent';
import Invoice from './Invoice';

//Images
import ExpandIcon from '../../../Components/Images/Icon_Expand_Black.svg';

//CSS
import '../Billing.css';


export default function BillingTableRow({
  rowData
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to determine if the row is expanded/collapsed
    const [rowExpanded, setRowExpanded] = useState(false);

    // Current selected invoice
    const [currentInvoice, setCurrentInvoice] = useState();

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (
      <>

        {/* Show a modal when an invoice is selected */}
        {
          currentInvoice && (
          <ModalContent
            modalVisible = {true}
            setModalVisible = {() => setCurrentInvoice(undefined)}
            heading = {`Invoice: ${currentInvoice?.invoicenumber}`}
            body = {
              <Invoice
                invoice={currentInvoice}>
              </Invoice>
            }
          ></ModalContent>
          )
        }

        {/* Header Row */}
        <tr onClick={() => setRowExpanded(!rowExpanded)}>
          <th colSpan="4">
            <div className='Billing-Table-Header-Row'>
              {rowData.projectId}
              {
                rowExpanded ? (
                  <img src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon"></img>
                ) : (
                  <img src={ExpandIcon} alt="Expand_Icon"></img>
                )
              }
            </div>
          </th>
        </tr>
        {/*  */}
        {
          rowExpanded ? (
            rowData?.invoices?.map((invoice) =>(
              <>
                <tr>
                  <th>
                    Invoice number
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Total
                  </th>
                  <th style={{textAlign: "right", padding: "10px 30px"}}>
                    <button onClick={() => setCurrentInvoice(invoice)} className='Primary-Button'>Open invoice</button>
                  </th>
                </tr>
                <tr>
                  <td>
                    {invoice.invoicenumber}
                  </td>

                  <td>
                    {invoice.invoicePeriod}
                  </td>

                  <td>
                    ${invoice?.Totals?.invoiceTotal}
                  </td>
                </tr>
              </>
            ))

          ) : 
          (
            null
          )
        }
      </>
    )
      
}
